import React, { useEffect, useState } from 'react'
import { withStyles, createStyles, Tooltip, Typography } from '@material-ui/core'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { ShowChart, TableChart, HorizontalSplit } from '@material-ui/icons'
import { muiOptions, defaultColors, defaultStyles, MuiProps } from '../../infrastructure/materialUiThemeProvider'
import { t } from '../../infrastructure/i18nextHelper'
import { MktSalesContainer } from './mktSalesStore'
import moment from 'moment'
import * as api from '../../infrastructure/api'
import { snackbars } from '../../infrastructure/snackbars'
import { hasFeature } from '../../infrastructure/feature'
import { Button, Menu } from '../common/customComponents'
import { Edit, Save, MoreVert, PublishOutlined, GetAppOutlined } from '@material-ui/icons'
import { hasClaim } from '../../infrastructure/signIn/userContext'
import { Claims } from '../../infrastructure/signIn/models'
import { ExportMktSalesArgs, ForecastSnapshotParam, SnapshotType, MktSaleForecastSnapshotInfo } from './models'
import { Guid } from '@guid'
import { SnapshotDialogs } from './_snapshotDialogs'
import { useSnapshotLabel } from './useSnapshotLabel'

let _MktSalesBoardHeader = ({ classes }: MuiProps) => {
    let store = MktSalesContainer.useContainer()
    let [isSnapshotCancellationDialogOpen, setIsSnapshotCancellationDialogOpen] = useState<boolean>(false)
    let [isSnapshotCreationDialogOpen, setIsSnapshotCreationDialogOpen] = useState<boolean>(false)
    let [snapshotType, setSnapshotType] = useState<SnapshotType | null>(null)
    let [existingInitialSnapshot, setExistingInitialSnapshot] = useState<MktSaleForecastSnapshotInfo | null>(null)
    let [existingLongTermPlanSnapshot, setExistingLongTermPlanSnapshot] = useState<MktSaleForecastSnapshotInfo | null>(null)

    let { getSnapshotLabel } = useSnapshotLabel()

    let existingSnapshot = (type: SnapshotType) => {
        return type === 'Initial' ? existingInitialSnapshot : existingLongTermPlanSnapshot
    }

    let openForecastPopup = () => store.setShowForecastPopup(true)

    let showCreationDialog = (type: SnapshotType) => {
        setSnapshotType(type)
        setIsSnapshotCreationDialogOpen(true)
    }

    let showCancellationDialog = (type: SnapshotType) => {
        setSnapshotType(type)
        setIsSnapshotCancellationDialogOpen(true)
    }

    let hideCreationDialog = () => {
        setSnapshotType(null)
        setIsSnapshotCreationDialogOpen(false)
    }

    let hideCancellationDialog = () => {
        setSnapshotType(null)
        setIsSnapshotCancellationDialogOpen(false)
    }

    let isManager = hasClaim(Claims.SalesManager)

    let uploadActualFile = async (e) => {
        await uploadFile('stock/movement/mktsale/import/actualMktSales', e)
        snackbars.success(t('httpSuccess.actualSalesImported'))
    }

    let uploadForecastFile = async (e) => {
        try {
            await uploadFile('stock/movement/mktsale/import/forecastMktSales', e)
        }
        catch (e) {
            if (e.status == 504 && hasFeature('MktSalesForecastShipTo'))
                snackbars.info(t('mktSales.label.info.tactixOutput'))
            throw e
        }
    }

    let uploadFile = async (url: string, e) => {
        await api.upload(url, e.target.files[0], 'import')
        await store.loadMktSaleMovements()
    }

    let exportMktSales = async () => {
        let date = !store.mktSalesFilters?.month ? moment.utc().startOf('month') : moment(store.mktSalesFilters.month)
        let dateFrom = date.startOf('month').format('YYYYMMDD')
        let dateTo = date.endOf('month').format('YYYYMMDD')
        let filename = `${dateFrom}-${dateTo}-sales.xlsx`

        let toArray = (array?: string[] | Guid[] | null) => !!array && array.length > 0 ? array : null

        let filters: ExportMktSalesArgs = {
            filename: filename,
            month: store.mktSalesFilters.month,
            site: toArray(store.mktSalesFilters?.site),
            productId: toArray(store.mktSalesFilters?.productId),
            companies: toArray(store.mktSalesFilters?.companies),
            customerSegments: toArray(store.mktSalesFilters?.customerSegments)
        }

        await api.post('stock/movement/mktsale/export', filters)

        let url = `${api.url}download/${filename}`
        window.open(url, '_blank')
    }

    let loadExistingForecastSnapshot = (type: SnapshotType) => {

        if (type == 'LongTermPlan' && !hasFeature('MktSalesLongTermPlan'))
            return

        let getExistingSnapshot = async (arg: ForecastSnapshotParam) => {
            var snapshot = await getForecastSnapshotInfo(arg)
            switch (type) {
                case 'Initial':
                    setExistingInitialSnapshot(snapshot)
                    break
                case 'LongTermPlan':
                    setExistingLongTermPlanSnapshot(snapshot)
                    break
            }
        }

        if (!!store.mktSalesFilters?.companies?.length && !!store.mktSalesFilters?.month) {
            if (store.mktSalesFilters?.companies?.length === 1 || !store.showForecastPopup) {
                let params = getForecastSnapshotParam(type)
                getExistingSnapshot(params)
            }
        }
    }

    useEffect(() => { loadExistingForecastSnapshot('Initial') }, [store.mktSalesFilters, store.showForecastPopup])
    useEffect(() => { loadExistingForecastSnapshot('LongTermPlan') }, [store.mktSalesFilters, store.showForecastPopup])

    let getForecastSnapshotInfo = async (arg: ForecastSnapshotParam) =>
        await api.get<MktSaleForecastSnapshotInfo | null>(`stock/movement/mktsale/forecastSnapshot/${arg.company}/${arg.month}/${arg.type}`)

    let getForecastSnapshotParam = (type: SnapshotType): ForecastSnapshotParam => {
        let month = moment(store.mktSalesFilters.month)
        let date = month.startOf('month').format('yyyy-MM-DD')
        let company: string = store.mktSalesFilters!.companies![0]

        return {
            company: company,
            month: date,
            type
        }
    }

    let createSnapshot = async (type: SnapshotType) => {
        let params = getForecastSnapshotParam(type)
        await api.post<ForecastSnapshotParam>('stock/movement/mktsale/snapshot', params)
        snackbars.success(t('mktSales.forecast.saveForecastSuccess', { type: getSnapshotLabel(type) }))
        hideCreationDialog()

        await store.loadMktSaleMovements()
        loadExistingForecastSnapshot(type)
    }

    let cancelSnapshot = async (type: SnapshotType) => {
        await api.del(`stock/movement/mktsale/snapshot/${existingSnapshot(type)!.id}`)
        snackbars.success(t('mktSales.forecast.cancelForecastSuccess', { type: getSnapshotLabel(type) }))
        hideCancellationDialog()

        await store.loadMktSaleMovements()
        loadExistingForecastSnapshot(type)
    }

    let refreshMktSalesDeals = async () => {
        await api.post('deal/mktSalesDeals', { allCountry: false })
        snackbars.success(t('mktSales.internalDeal.operationCompleted'))
    }

    let snapshotTypes: SnapshotType[] = hasFeature('MktSalesLongTermPlan') ? ['Initial', 'LongTermPlan'] : ['Initial']

    return (
        <>
            <div className={classes.container}>
                <div className={classes.paperHeader}>
                    <div className={classes.modeSelectors}>
                        <ToggleButtonGroup
                            value={store.displayMode}
                            exclusive
                            onChange={(_, newValue) => { if (newValue) store.setDisplayMode(newValue) }}>
                            <Tooltip title={<Typography variant='body1'>{t('mktSales.label.splitMode')}</Typography>} placement='top'>
                                <ToggleButton value='split' selected={store.displayMode == 'split'} classes={{ selected: classes.selected }}>
                                    <HorizontalSplit />
                                </ToggleButton>
                            </Tooltip>
                            <Tooltip title={<Typography variant='body1'>{t('mktSales.label.graphMode')}</Typography>} placement='top'>
                                <ToggleButton value='graph' selected={store.displayMode == 'graph'} classes={{ selected: classes.selected }}>
                                    <ShowChart />
                                </ToggleButton>
                            </Tooltip>
                            <Tooltip title={<Typography variant='body1'>{t('mktSales.label.tableMode')}</Typography>} placement='top'>
                                <ToggleButton value='table' selected={store.displayMode == 'table'} classes={{ selected: classes.selected }}>
                                    <TableChart />
                                </ToggleButton>
                            </Tooltip>
                        </ToggleButtonGroup>
                    </div>
                    <div className={classes.rightButtonsContainer}>
                        {isManager && hasFeature('MktSalesDeals')
                            && <Button onClick={refreshMktSalesDeals}
                                label={t('mktSales.internalDeal.refreshButton')}
                                className={classes.refreshDealButton} />
                        }
                        {store.mktSalesFilters?.companies?.length === 1 &&
                            snapshotTypes.map(type => (
                                <React.Fragment key={type}>
                                    {existingSnapshot(type)
                                        ? <Typography variant='h6' className={classes.snapshotText}>{t('mktSales.forecast.existingSnapshot', { type: getSnapshotLabel(type) }) +
                                            moment(existingSnapshot(type)!.createdAt).format('MM/DD/YYYY')}</Typography>
                                        : isManager && <Button onClick={() => showCreationDialog(type)}
                                            label={t('mktSales.forecast.saveForecast', { type: getSnapshotLabel(type) })}
                                            className={classes.secondaryButton} />
                                    }
                                    {existingSnapshot(type) && isManager
                                        && <Button onClick={() => showCancellationDialog(type)}
                                            label={t('mktSales.forecast.cancelForecast', { type: getSnapshotLabel(type) })}
                                            className={classes.secondaryButton} />
                                    }
                                </React.Fragment>))
                        }
                        {isManager && (hasFeature('MktSalesSiteVolume') || hasFeature('MktSalesEditForecastVolume')) &&
                            <Button onClick={store.toggleTableEditMode}
                                img={!store.tableEditMode.isOn ? (<Edit />) : (<Save />)}
                                label={t(`trucks.table.${!store.tableEditMode.isOn ? 'edit' : 'save'}`)}
                                className={classes.editButton} />
                        }
                        {hasFeature('MktSalesForecast') &&
                            <Button onClick={openForecastPopup}
                                label={t('mktSales.table.add')}
                                className={classes.openPopupButton} />
                        }
                        <Menu
                            icon={<MoreVert />}
                            items={[...(isManager ? [{
                                text: t('mktSales.table.uploadActual'),
                                isUpload: true,
                                onUpload: uploadActualFile,
                                icon: <PublishOutlined className={classes.iconStyle} />,
                            },
                            {
                                text: t('mktSales.table.uploadForecast'),
                                isUpload: true,
                                onUpload: uploadForecastFile,
                                icon: <PublishOutlined className={classes.iconStyle} />,
                            }] : []),
                            {
                                text: t('mktSales.table.exportSales'),
                                icon: <GetAppOutlined className={classes.iconStyle} />,
                                onClick: exportMktSales,
                            }]}
                            classesOverride={{ button: classes.menuButton }} />
                    </div>
                </div>
            </div>
            <SnapshotDialogs snapshotType={snapshotType}
                isSnapshotCreationDialogOpen={isSnapshotCreationDialogOpen}
                hideCreationDialog={hideCreationDialog}
                createSnapshot={createSnapshot}
                isSnapshotCancellationDialogOpen={isSnapshotCancellationDialogOpen}
                hideCancellationDialog={hideCancellationDialog}
                cancelSnapshot={cancelSnapshot} />
        </>
    )
}

let styles = _ =>
    createStyles({
        container: {
            height: '100%',
            width: '100%',
            paddingBottom: '-1em'
        },
        paperHeader: {
            position: 'relative',
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            margin: '0em 1em',
            marginTop: '0.5em',
            marginBottom: '-1em',
            zIndex: 3,
            userSelect: 'none'
        },
        modeSelectors: {
            display: 'flex',
            alignItems: 'center'
        },
        menuButton: {
            padding: '5px'
        },
        openPopupButton: {
            ...defaultStyles.primaryButton,
            marginRight: '0.5em'
        },
        editButton: {
            ...defaultStyles.secondaryButton,
            padding: '0px',
            cursor: 'pointer',
            marginRight: '0.8em'
        },
        iconStyle: { height: '1.5rem', width: '1.5rem', marginTop: '-0.2rem' },
        rightButtonsContainer: {
            ...defaultStyles.flexRow
        },
        selected: {
            "&&": {
                backgroundColor: defaultColors.lightBlue.main.color,
                color: 'white',
                '&:hover': {
                    backgroundColor: defaultColors.lightBlue.light.color,
                },
            }
        },
        secondaryButton: {
            ...defaultStyles.secondaryButton,
            marginRight: '0.8em'
        },
        snapshotText: {
            color: defaultColors.darkBlue.main.color,
            marginRight: '0.8em'
        },
        refreshDealButton: {
            ...defaultStyles.secondaryButton,
            marginRight: '0.8em'
        }
    })

export let MktSalesBoardHeader = withStyles(styles, muiOptions)(_MktSalesBoardHeader)