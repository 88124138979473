import React from 'react'
import { MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import { Select, NumberField, DateRangePicker } from '../../common/customComponents'
import { VesselProduct } from '../vesselModels'
import { hasFeature } from '../../../infrastructure/feature'
import { Item } from '../../common/components/select/types'

type FormulaPriceDataRowProps = {
    product: VesselProduct,
    quotations: Item[],
    changeProduct: (product: VesselProduct) => void
}

export function FormulaPriceDataRow({ classes, product, quotations, changeProduct }: FormulaPriceDataRowProps & MuiProps) {
    return (<div className={classes.productDataRow}>
        <Select label={t('vessels.label.product.quotationCode')}
            value={product.quotationCode}
            choices={quotations}
            onChange={val => changeProduct({ ...product, quotationCode: val })} />
        {!hasFeature('VesselMultiplePricingPeriod') ?
            <DateRangePicker startDate={product.pricingStartDate} startLabel={t('vessels.label.product.pricingStartDate')}
                endDate={product.pricingEndDate} endLabel={t('vessels.label.product.pricingEndDate')} autoReplace={true}
                onPeriodChange={period =>
                    changeProduct({ ...product, pricingStartDate: period.startDate, pricingEndDate: period.endDate })} />
            : undefined}
        {product.premiumCurrency && !hasFeature('VesselMultiplePremium') ?
            <NumberField label={t('vessels.label.product.premium', { unit: product.premiumCurrency })}
                onChange={val => changeProduct({ ...product, premium: val })}
                text={product.premium}
                allowNegative={true} />
            : undefined}
    </div>)
}

type FixedPriceDataRowProps = {
    productUnit: string,
    changeFixedPrice: (fixedPrice: number | null) => void,
    value: number | null
}

export function FixedPriceDataRow({ classes, productUnit, changeFixedPrice, value }: FixedPriceDataRowProps & MuiProps) {
    return (<div className={classes.productDataRow}>
        <NumberField label={t('vessels.label.purchaseMovement.fixedPrice', { unit: productUnit })}
            onChange={val => changeFixedPrice(val)}
            text={value} />
    </div>)
}
