import React from 'react'
import { createStyles, Select as MuiSelect, MenuItem } from '@material-ui/core'
import { muiOptions, defaultColors, MuiProps } from '../../../../infrastructure/materialUiThemeProvider'
import { withStyles } from '@material-ui/styles'
import { MultipleSelect as _MultipleSelect } from './multiSelect'
import { GroupedMultiSelect as _GroupedMultiSelect } from './groupedSelect'
import { Item } from './types'

type HeaderSelectProps = {
    choices: Item[] | string[]
    value: string
    onChange: (value: string) => void
    size?: 'small' | 'standard'
}

function _HeaderSelect(props: HeaderSelectProps & MuiProps) {
    let size = props.size === 'small' ? props.classes.small : props.classes.standard

    return (
        <MuiSelect
            value={props.value}
            className={props.classes.select + ' ' + size}
            label={''}
            disableUnderline
            disabled={props.choices.length <= 1}
            inputProps={{ style: { padding: '2em' } }}
            IconComponent={() => null}
            onChange={e => props.onChange(e.target.value as string)}>
            {props.choices.map((x, i) =>
                <MenuItem key={i} value={x.value}>
                    {x.text}
                </MenuItem>
            )}
        </MuiSelect>
    )
}

let styles = _ =>
    createStyles({
        select: {
            '& > div:focus': {
                backgroundColor: 'white'
            },
            fontStyle: 'italic',
            color: defaultColors.grey.main.color,
        },
        selected: {
            backgroundColor: '#EBEBEB !important'
        },
        small: { width: '3em' },
        standard: { width: '11em' }
    })

export let HeaderSelect = withStyles(styles, muiOptions)(_HeaderSelect)