import React, { useState, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import { hasFeature } from '../../../infrastructure/feature'
import { NumberField, DatePicker, Select } from '../../common/customComponents'
import { HelpButton } from '../../common/components/helpButton'
import { CostingInformation } from '../vesselCostingInformationModels'
import { Item } from '../../common/components/select/types'

let tPrefix = 'vessels.label.product.costingInformation.'

type CostNumberFieldProps = {
    label: string,
    prop: keyof CostingInformation,
    disabled?: boolean,
    auto?: boolean,
    costingInformation: CostingInformation,
    onChange: (...props: { prop: keyof CostingInformation, value: number | boolean | string | null }[]) => void,
    isAuthorized: (prop: string) => boolean
}

export function CostNumberField({ label, prop, disabled, auto, costingInformation, onChange, isAuthorized }: CostNumberFieldProps) {
    if (!isAuthorized(prop)) return null

    return (
        <NumberField label={label}
            precision={5}
            onChange={(newValue) => onChange({ prop: prop, value: newValue })}
            disabled={disabled}
            auto={auto}
            text={costingInformation[prop] as (number | null)} />
    )
}

type CostDateFieldProps = {
    label: string,
    prop: keyof CostingInformation,
    disabled?: boolean,
    auto?: boolean,
    costingInformation: CostingInformation,
    onChange: (value: string | null) => void,
    isAuthorized: (prop: string) => boolean,
}

export function CostDatePicker({ label, prop, disabled, auto, costingInformation, onChange, isAuthorized }: CostDateFieldProps) {
    if (isAuthorized(prop))
        return (
            <DatePicker label={label}
                setDate={(newValue) => onChange(newValue)}
                disabled={disabled}
                auto={auto}
                date={costingInformation[prop] as string} />
        )
    else return null
}

type CostSelectProps = {
    label: string,
    prop: keyof CostingInformation,
    choices: Item[]
    disabled?: boolean,
    auto?: boolean,
    costingInformation: CostingInformation,
    onChange: (...props: { prop: keyof CostingInformation, value: number | boolean | string | null }[]) => void,
    isAuthorized: (prop: string) => boolean,
}

export function CostSelect({ label, prop, disabled, auto, costingInformation, choices, onChange, isAuthorized }: CostSelectProps) {
    if (!isAuthorized(prop)) return null
    return (
        <Select label={label}
            onChange={(newValue) => onChange({ prop: prop, value: newValue })
            }
            disabled={disabled}
            auto={auto}
            value={costingInformation[prop]}
            choices={choices} />
    )
}

type DemurrageTimestampPickerProps = { demurrageMinutes: number | null, overrided: boolean, onChange: (value: number | null) => void }

export function DemurrageTimestampPicker({ demurrageMinutes, onChange, overrided, classes }: DemurrageTimestampPickerProps & MuiProps) {
    let hoursInDay = 24
    let minutesInHour = 60
    let minutesInDay = minutesInHour * hoursInDay
    let isDayHours = hasFeature('DealDemurrageDayHours')
    let calculateHours = (minutes) => isDayHours ? Math.floor(minutes / minutesInHour) % hoursInDay : Math.floor(minutes / minutesInHour)
    let [days, setDays] = useState<number | null>(demurrageMinutes !== null ? Math.floor(demurrageMinutes / minutesInDay) : null)
    let [hours, setHours] = useState<number | null>(demurrageMinutes !== null ? calculateHours(demurrageMinutes) : null)
    let [minutes, setMinutes] = useState<number | null>(demurrageMinutes !== null ? demurrageMinutes % minutesInHour : null)
    let [daysOverridden, setDaysOverridden] = useState<boolean>(!overrided)
    let [hoursOverridden, setHoursOverridden] = useState<boolean>(!overrided)
    let [minutesOverridden, setMinutesOverridden] = useState<boolean>(!overrided)

    useEffect(() => {
        setDays(demurrageMinutes !== null ? Math.floor(demurrageMinutes / minutesInDay) : null)
        setHours(demurrageMinutes !== null ? calculateHours(demurrageMinutes) : null)
        setMinutes(demurrageMinutes !== null ? demurrageMinutes % minutesInHour : null)
    }, [demurrageMinutes])

    let change = (days, hours, minutes, minutesOverridden, hoursOverridden, daysOverridden) => {
        if ((!minutesOverridden || !daysOverridden) && !hoursOverridden)
            onChange(null)
        else
            onChange((days ?? 0) * minutesInDay + (hours ?? 0) * minutesInHour + (minutes ?? 0))
    }

    let changeDays = (value) => {
        setDaysOverridden(!!value)
        setDays(value)
        change(value, hours, minutes, false, hoursOverridden, !!value)
    }

    let changeHours = (value) => {
        setHoursOverridden(!!value)
        setHours(value)

        if (isDayHours)
            change(days, value, null, minutesOverridden, !!value, daysOverridden)
        else
            change(null, value, minutes, minutesOverridden, !!value, daysOverridden)
    }

    let changeMinutes = (value) => {
        setMinutesOverridden(!!value)
        setMinutes(value)
        change(null, hours, value, !!value, hoursOverridden, false)
    }

    return (
        <div className={classes.demurrageTimeRow}>
            <NumberField
                precision={0} min={0} max={isDayHours ? 4166 : 100000}
                onChange={(newValue) => isDayHours ? changeDays(newValue) : changeHours(newValue)
                }
                text={isDayHours ? days : hours}
                label={isDayHours ? t(tPrefix + 'demurrageDays') : t(tPrefix + 'demurrageHours')} />
            <div className={classes.tooltipContainer}>
                <HelpButton
                    title={t(tPrefix + 'demurrageMinutesTooltipTitle')}
                    text={t(tPrefix + 'demurrageMinutesTooltipText')}
                    height={'1.2em'}
                    width={'1.2em'}
                    fullWidth
                    placement={'top-end'} />
                <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>: </Typography>
            </div>
            <NumberField
                precision={0} min={0} max={isDayHours ? 23 : 59}
                onChange={(newValue) => isDayHours ? changeHours(newValue) : changeMinutes(newValue)}
                text={isDayHours ? hours : minutes}
                label={isDayHours ? t(tPrefix + 'demurrageHours') : t(tPrefix + 'demurrageMinutes')} />
        </div>
    )
}
