import moment from 'moment'
import * as Icons from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { Paper, Typography, createStyles, withStyles, Input, Tooltip } from '@material-ui/core'
import { t } from '../../infrastructure/i18nextHelper'
import { ColumnDescriptor, DataTable, LabelButton, CustomDialog, Switch, Link, Menu } from '../common/customComponents'
import { defaultColors, muiOptions, MuiProps, defaultStyles } from '../../infrastructure/materialUiThemeProvider'
import { MessageMovement, MessageMovementFilters, MessageMovementType, MovementType } from './stockModels'
import * as api from '../../infrastructure/api'
import { snackbars } from '../../infrastructure/snackbars'
import { ActionDescriptor } from '../common/components/table/table'
import { useActionDebounceWithArgs } from '../common/debounce'
import { StockBoardContainer } from './stockBoardStore'
import guid from '../../infrastructure/guid';
import { allowedExtensions } from '../common/fieldProps'
import { LoaderStatusContainer } from '../../infrastructure/loader'
import { MovementMessageContainer } from './movementMessageStore'
import { ExcelGeneratorContainer } from '../../infrastructure/excelExport'
import { createExcelLines } from "./../administration/masterData/masterDataShell"
import { movementDialog } from './movementEdit/stockMovementStore'
import { StockFiltersContainer } from './filters/filtersStore'

const tBase = 'stock.label.message.'
const tBaseTable = tBase + 'table.'
const tBaseMessageAction = tBase + 'messageAction.'

type ButtonParams = {
    buttonText?: string
    isDisplayed: boolean
}

type MessagesMovementFilters = {
    reference: string | null
    siteCode: string | null
}

let noFilters: MessagesMovementFilters = {
    reference: null,
    siteCode: null
}

let toTableItem = (messageMovement: MessageMovement): MessageMovement => {
    return {
        id: messageMovement.id,
        direction: messageMovement.direction,
        startDate: messageMovement.startDate,
        endDate: messageMovement.endDate,
        country: messageMovement.country,
        type: messageMovement.type,
        status: messageMovement.status,
        timestamp: messageMovement.timestamp,
        reference: messageMovement.reference,
        reference2: messageMovement.reference2,
        reference3: messageMovement.reference3,
        reference4: messageMovement.reference4,
        part: messageMovement.part,
        volume: messageMovement.volume,
        unit: messageMovement.unit,
        productCode: messageMovement.productCode,
        counterparty: messageMovement.counterparty,
        site: messageMovement.site,
        siteCode: messageMovement.siteCode,
        hasMovementWithSameReference: messageMovement.hasMovementWithSameReference,
        flowRate: messageMovement.flowRate,
        operationStartAt: messageMovement.operationStartAt,
        operationEndAt: messageMovement.operationEndAt,
        instruction: messageMovement.instruction,
        externalReference: messageMovement.externalReference
    }
}

type MovementMessageProps = {
    className?: string
    movementPopupHalfBottom?: boolean
}

function _MovementMessageTable({ classes, className, movementPopupHalfBottom }: MuiProps & MovementMessageProps) {
    let store = StockBoardContainer.useContainer()
    let stockFiltersStore = StockFiltersContainer.useContainer()
    let loader = LoaderStatusContainer.useContainer()
    let excelGenerator = ExcelGeneratorContainer.useContainer()
    let movementMessageStore = MovementMessageContainer.useContainer()
    let isMessagesDisplayed = store.displayedBlocks.includes('messageTable')
    let [currentMessage, setCurrentMessage] = React.useState<MessageMovement | null>(null)
    let [isOpen, setIsOpen] = useState<boolean>(false)
    let [messages, setMessages] = useState<MessageMovement[]>([])
    let [isEdiSelected, setEdiSelection] = useState<boolean>(false)
    let [selectedMessages, setSelectedMessages] = useState<MessageMovement[]>([])
    let [isBatchDelete, setIsBatchDelete] = useState<boolean>(false)
    let [filters, setFilters] = useState<MessagesMovementFilters>(noFilters)

    useEffect(() => {
        if (isMessagesDisplayed) loadMessages()
    }, [isMessagesDisplayed, isEdiSelected, stockFiltersStore.filters.sites, stockFiltersStore.filters.productIds])

    let searchMovements = (reference: string) => {
        movementMessageStore.setSearchText(reference)
        store.setTextFilter(reference)
    }

    let applyFilters = (messagesMovements: MessageMovement[]): MessageMovement[] => {
        if (filters.siteCode)
            messagesMovements = messagesMovements.filter(x => x.siteCode?.toLowerCase().contains(filters.siteCode!.toLowerCase()))
        if (filters.reference)
            messagesMovements = messagesMovements.filter(x => x.reference?.toLowerCase().contains(filters.reference!.toLowerCase()))
        return messagesMovements
    }

    let getMessages = () => applyFilters(messages).map(toTableItem)

    let columns = getColumns(isEdiSelected ? 'Edi' : 'OpsNotice')
        .map(column =>
            column.code == 'site' ? { ...column, columnFilter: { value: filters.siteCode ?? '', onChange: (siteCode: string) => setFilters({ ...filters, siteCode }) } }
                : column.code == 'reference' ?
                    {
                        ...column,
                        columnFilter: { value: filters.reference ?? '', onChange: (reference: string) => setFilters({ ...filters, reference }) },
                        htmlFor: x => ReferenceCell(x, searchMovements)
                    }
                    : column
        )

    let actions: ActionDescriptor<MessageMovement>[] =
        [
            {
                name: 'CreateTransferMovement', action: (x: MessageMovement) => { createMovement(x, MovementType.Transfer, getTransferIsInOut(x)) },
                icon:
                    <Tooltip title={<Typography variant='body1'>{t(tBaseMessageAction + 'tooltips.createTransferMovement')}</Typography>} placement='top'>
                        <Icons.SwapHoriz />
                    </Tooltip>,
                isBodyAction: true
            },
            {
                name: 'CreatePurchaseMovement', action: (x: MessageMovement) => { createMovement(x, MovementType.Purchase, true) },
                icon:
                    <Tooltip title={<Typography variant='body1'>{t(tBaseMessageAction + 'tooltips.createPurchaseMovement')}</Typography>} placement='top'>
                        <Icons.ShoppingCart />
                    </Tooltip>,
                isBodyAction: true
            },
            {
                name: 'CreateSaleMovement', action: (x: MessageMovement) => { createMovement(x, MovementType.Sale, false) },
                icon:
                    <Tooltip title={<Typography variant='body1'>{t(tBaseMessageAction + 'tooltips.createSaleMovement')}</Typography>} placement='top'>
                        <Icons.AttachMoney />
                    </Tooltip>,
                isBodyAction: true
            },
            {
                name: 'UpdateMovementReference', action: (x: MessageMovement) => changeMovementReference(x),
                icon:
                    <Tooltip title={<Typography variant='body1'>{t(tBaseMessageAction + 'tooltips.updateMovementReference')}</Typography>} placement='top'>
                        <Icons.Sync />
                    </Tooltip>
                ,
                isBodyAction: true
            },
            {
                name: 'UpdateMovement', action: (x: MessageMovement) => changeMovement(x),
                icon:
                    <Tooltip title={<Typography variant='body1'>{t(tBaseMessageAction + 'tooltips.updateMovement')}</Typography>} placement='top'>
                        <Icons.ArrowRightAlt />
                    </Tooltip>
                ,
                isBodyAction: true
            },
            {
                name: 'Delete', action: (x: MessageMovement) => { setCurrentMessage(x); setIsOpen(true) },
                icon: <Icons.DeleteOutlined />,
                isBodyAction: true
            }
        ]

    let uploadFile = async (url: string, e) => {
        await api.upload(url, e.target.files[0], 'import')
    }

    let getSelectedType = () => isEdiSelected ? MessageMovementType.EDI : MessageMovementType.OpsNotice

    let upload = async (e) => {
        let selectedType = getSelectedType()
        await uploadFile(`stock/movementMessage?messageType=${selectedType}`, e)
        e.target.value = ''
        loadMessages()
        snackbars.success(t('httpSuccess.importMessagesSuccess'))
    }

    let deleteMessages = async (ids: string[]) => {
        await api.del('stock/movementMessage', { ids: ids })
        loadMessages()
        snackbars.success(t('httpSuccess.deleteMessageSuccess'))
        setCurrentMessage(null)
        setIsBatchDelete(false)
        return true
    }

    let deleteMessageMovement = async (message: MessageMovement) => {
        deleteMessages([message.id])
    }

    let deleteSelectedMessages = () => {
        if (selectedMessages?.length === 0) return

        deleteMessages(selectedMessages.map(x => x.id))
    }

    let loadMessages = async () => {
        let filters = constructFilters()
        let messages = await api.get<MessageMovement[]>(`stock/movementMessage?${filters}`)
        setMessages(messages)
    }

    let deletMessageMovementDebouncer = useActionDebounceWithArgs(deleteMessageMovement)

    let closeConfirmationDialog = () => {
        setCurrentMessage(null)
        setIsBatchDelete(false)
        setIsOpen(false)
    }

    let buttons: ButtonParams[] = [{
        buttonText: t(tBaseTable + 'importOpsNotice'),
        isDisplayed: !isEdiSelected
    },
    {
        buttonText: t(tBaseTable + 'importEdi'),
        isDisplayed: isEdiSelected
    }]

    let getTransferIsInOut = (message: MessageMovement): boolean =>
        message.type === 'OpsNotice'
            ? message.direction === 'D'
            : store.switchFilter === 'in'

    let createMovement = async (message: MessageMovement, type: MovementType, isIn: boolean) => {
        let movementType = ''
        let movementId = guid.createNew()

        switch (type) {
            case MovementType.Purchase:
                movementType = 'Purchase'
                break
            case MovementType.Sale:
                movementType = 'Sale'
                break
            case MovementType.Transfer:
                movementType = isIn ? 'TransferIn' : 'TransferOut'
                break
        }

        await api.post(`stock/movementMessage/${message.id}/movement`, {
            messageId: message.id,
            movementId: movementId,
            movementType: movementType
        })

        snackbars.success(t('httpSuccess.movementCreated'))
        store.load()
        loadMessages()
        movementDialog.open(movementId, movementPopupHalfBottom)
    }

    let changeMovement = async (message: MessageMovement) => {
        if (movementMessageStore.selectedMovementIds.length === 0) return
        if (movementMessageStore.selectedMovementIds.length > 1) {
            snackbars.error(t(tBase + 'moreMovementsSelected'))
            return
        }

        let movementType = getTransferIsInOut(message)
            ? 'TransferIn'
            : 'TransferOut'
        await api.post(`stock/movementMessage/${message.id}/movement/${movementMessageStore.selectedMovementIds[0]}`,
            {
                messageId: message.id,
                movementId: movementMessageStore.selectedMovementIds[0],
                movementType
            })
        if (message && message.type == MessageMovementType.EDI)
            await deletMessageMovementDebouncer.execute(message)
        snackbars.success(t(tBase + 'movementChanged'))
        store.load()
        loadMessages()
    }

    let changeMovementReference = async (message: MessageMovement) => {
        if (movementMessageStore.selectedMovementIds.length === 0) return
        if (movementMessageStore.selectedMovementIds.length > 1) {
            snackbars.error(t(tBase + 'moreMovementsSelected'))
            return
        }

        await api.post(`stock/movementMessage/${message.id}/movementReference/${movementMessageStore.selectedMovementIds[0]}`,
            {
                messageId: message.id,
                movementId: movementMessageStore.selectedMovementIds[0],
            })

        snackbars.success(t(tBase + 'movementReferenceChanged'))
        store.load()
    }

    let toggleSwitch = () => setEdiSelection(!isEdiSelected)

    let constructFilters = () => {
        let filters: MessageMovementFilters = {
            productIds: stockFiltersStore.filters.productIds,
            type: getSelectedType()
        }

        let queries: string[] = []

        queries = queries.concat(filters.productIds?.map(x => `productIds=${x}`) ?? [])
        queries = queries.concat([`type=${filters.type}`])

        return queries.join('&')
    }

    let exportExcel = () => {
        excelGenerator.generate({
            filename: 'MovementMessages.xlsx',
            sheets: [{ name: 'Messages', lines: createExcelLines(messages, columns) }]
        })
    }

    return (
        <>
            <Paper className={classes.container}>
                <div className={classes.firstRow}>
                    <div className={classes.tableHeaderRow}>
                        <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                            {t(tBaseTable + 'title')}
                        </Typography>
                    </div>
                    <div className={classes.tableHeaderRow}>
                        <Switch form
                            isChecked={isEdiSelected}
                            disabled={loader.isActive}
                            changeCallback={toggleSwitch}
                            offText={t('stock.label.message.table.opsNotice')}
                            onText={t('stock.label.message.table.edi')} />
                    </div>
                    <div className={classes.importButtons}>
                        {buttons && buttons
                            .filter((item: ButtonParams) => item.isDisplayed)
                            .map((item: ButtonParams, index: number) =>
                                <LabelButton
                                    label={item.buttonText}
                                    className={classes.secondaryButton}
                                    key={index}>
                                    <Input style={{ display: 'none' }} type='file'
                                        onChange={upload}
                                        inputProps={allowedExtensions} />
                                </LabelButton>)}
                    </div>
                    <div className={classes.menuButton}>
                        <Menu icon={<Icons.MoreVert />}
                            items={[{
                                text: t(tBase + 'export'),
                                icon: <Icons.GetAppOutlined className={classes.iconStyle} />,
                                onClick: exportExcel
                            }, {
                                text: t(tBase + 'deleteMultiple'),
                                icon: <Icons.DeleteOutlined className={classes.iconStyle} />,
                                disabled: selectedMessages?.length === 0,
                                onClick: () => { setIsBatchDelete(true); setIsOpen(true) }
                            }]}
                        />
                    </div>
                </div>
                <div className={classes.tableContainer}>
                    <DataTable
                        items={getMessages()}
                        tableId={'message-movement-table'}
                        idSelector={(x: MessageMovement) => x.id}
                        columns={columns}
                        actions={actions}
                        onSelectionChange={(x: MessageMovement[]) => setSelectedMessages(x)}
                    />
                </div>
            </Paper>
            <CustomDialog isOpen={isOpen}
                title={t(tBaseMessageAction + (isBatchDelete ? 'batchDeleteMessageDialogTitle' : 'deleteMessageDialogTitle'))}
                contentText={t(tBaseMessageAction + (isBatchDelete ? 'batchDeleteMessageDialogText' : 'deleteMessageDialogText'))}
                confirmButtonText={t(tBaseMessageAction + 'deleteMessageButton')}
                onConfirm={async () => {
                    setIsOpen(false)
                    if (currentMessage)
                        await deletMessageMovementDebouncer.execute(currentMessage)
                    else if (isBatchDelete)
                        deleteSelectedMessages()
                }}
                onClose={closeConfirmationDialog}
                onCancel={closeConfirmationDialog}
            />
        </>
    )
}

export function getColumns(mode: 'Edi' | 'OpsNotice' | 'All'): (ColumnDescriptor<MessageMovement> & { code?: string })[] {
    return [
        { name: t(tBaseTable + 'direction'), value: x => x.direction ?? '' },
        ...mode == 'OpsNotice' ? [] : [{ name: t(tBaseTable + 'reference3'), value: x => x.reference3 ?? '' }],
        ...mode == 'Edi' ? [] : [{ name: t(tBaseTable + 'startDate'), value: x => !!x.startDate ? moment(x.startDate.toString()).format('MM/DD h:mm A') : null }],
        { name: t(tBaseTable + 'endDate'), value: x => !!x.endDate ? moment(x.endDate?.toString()).format('MM/DD h:mm A') : null },
        { name: t(tBaseTable + 'product'), value: x => x.productCode ?? '' },
        { name: t(tBaseTable + 'site'), code: 'site', value: x => x.siteCode ?? '' },
        { name: t(tBaseTable + 'reference'), code: 'reference', value: x => x.reference ?? '' },
        { name: t(tBaseTable + 'volume'), value: x => x.volume ?? '' },
        ...mode == 'Edi' ? [] : [{ name: t(tBaseTable + 'flowRate'), value: x => x.flowRate ?? '' }],
        { name: t(tBaseTable + 'reference4'), value: x => x.reference4 ?? '' },
        ...mode == 'OpsNotice' ? [] : [{ name: t(tBaseTable + 'docket'), value: x => x.instruction ?? '' }],
        ...mode == 'OpsNotice' ? [] : [{ name: t(tBaseTable + 'subDocket'), value: x => x.externalReference ?? '' }],
    ]
}

let ReferenceCell = (message: MessageMovement, changeReference: (reference: string | null) => void) => {
    return (
        message.hasMovementWithSameReference
            ? <Link
                onClick={() => changeReference(message.reference)}>
                {message.reference}
            </Link>
            : <>{message.reference}</>
    )
}

let styles = theme =>
    createStyles({
        iconStyle: { height: '1.5rem', width: '1.5rem', marginTop: '-0.2rem' },
        invisible: { opacity: 0 },
        red: { color: defaultColors.red.light.color },
        grey: { color: defaultColors.grey.main.color },
        black: { color: 'black' },
        green: { color: defaultColors.green.main.color },
        firstRow: {
            ...defaultStyles.flexRow,
            alignItems: 'stretch',
            justifyContent: 'space-between',
            margin: '0.2em 1em',
            marginRight: '0',
            height: '3.2em'
        },
        container: {
            height: '100%',
            ...defaultStyles.flexColumn,
            alignItems: 'stretch'
        },
        tableHeaderRow: {
            ...defaultStyles.flexRow,
        },
        tableContainer: {
            overflow: 'auto',
            "& td": {
                padding: "3px 8px"
            }
        },
        paperTitle: {
            color: defaultColors.red.main.color,
        },
        secondaryButton: {
            ...defaultStyles.secondaryButton,
            marginRight: '0.8em'
        },
        importButtons: {
            textAlign: 'right',
            minWidth: '200px',
            paddingTop: '0.2em'
        },
        menuButton: {
            padding: '5px'
        }
    })

export let MovementMessageTable = withStyles(styles, muiOptions)(_MovementMessageTable)