import { t } from '../../infrastructure/i18nextHelper'
import { SnapshotType } from './models'

export let useSnapshotLabel = () => {
    let initialForecastLabel: string = t('mktSales.forecast.initial')
    let longTermPlanLabel: string = t('mktSales.forecast.longTermPlan')
    const getSnapshotLabel = (type: SnapshotType) => type === 'Initial' ? initialForecastLabel : longTermPlanLabel

    return { getSnapshotLabel, initialForecastLabel, longTermPlanLabel }
}
