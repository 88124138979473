import React from 'react'
import moment from 'moment'
import { withStyles, createStyles, Typography, Tooltip } from '@material-ui/core'
import { MyLocation, PanTool, DeleteOutlined, LocalShipping, VerifiedUser, CheckCircle, ErrorOutlineOutlined, History, Train } from '@material-ui/icons'
import { muiOptions } from '../../../../infrastructure/materialUiThemeProvider'
import { hasClaim } from '../../../../infrastructure/signIn/userContext'
import { Claims } from '../../../../infrastructure/signIn/models'
import { FeatureContainer } from '../../../../infrastructure/feature'
import { style } from './style'
import { truckStatuses, TruckTransportItem } from '../../truckModels'
import { TruckTransportContainer } from '../../truckStore'
import { DataTable, ColumnDescriptor } from '../../../common/customComponents'
import { historyDialog } from '../../../common/history/dialog'
import { popupNavigator } from '../../../../infrastructure/popupNavigator'
import { ActionDescriptor } from 'src/app/common/components/table/table'
import { CommentButton } from '../../../common/comment/commentButton'

function TruckTable({ classes }) {
    let store = TruckTransportContainer.useContainer()
    let features = FeatureContainer.useContainer()
    let t = store.t

    let hasBorderFeature = (!store.isRailCar && features.hasFeature('TruckBorder'))
        || (store.isRailCar && features.hasFeature('RailBorder'))
    let hasBorder2Feature = (!store.isRailCar && features.hasFeature('TruckBorder2'))
        || (store.isRailCar && features.hasFeature('RailBorder2'))

    let showComment = ((store.isRailCar && features.hasFeature('RailDeliveryFields')) || features.hasFeature('TruckDeliveryFields')) && features.hasFeature('CommentV2')

    let openForm = (truck: TruckTransportItem) => popupNavigator.open('truck', truck.id)

    let openHistory = (truck: TruckTransportItem) => {
        historyDialog.open({
            id: truck.id, type: 'truck', name: `
                    ${truck.truckNumber ?? ''}
                    ${truck.productCode ?? ''}
                    ${truck.customerSegment ?? ''}`
        })
    }

    let tryRoundAndStringify = (value: number | null | undefined, isBetweenParenthesis: boolean = false): string | null => {
        if (!!value) {
            let rounded = Math.round(value).toString()
            if (isBetweenParenthesis)
                return '(' + rounded + ')'
            else
                return rounded
        }
        return null
    }

    let observedColumn: ColumnDescriptor<TruckTransportItem>[] = features.hasFeature('ObservedQuantityTemperature')
        ? [{
            name: t('trucks.table.observedQuantity'),
            htmlFor: x =>
                <div>
                    <Typography variant='body2' display='inline'>{tryRoundAndStringify(x.loadedObservedQuantity)}</Typography>
                    <Typography variant="caption">{tryRoundAndStringify(x.loadedObservedTemperature, true)}</Typography>
                </div>
            ,
            total: store.list.listItems.map(x => x.loadedObservedQuantity).reduce((x, acc) => (acc ?? 0) + (x ?? 0), 0),
            totalLabel: t('components.table.total')
        }]
        : []

    let commentColumn: ColumnDescriptor<TruckTransportItem>[] = showComment
        ? [{
            preventRowClick: true,
            textAlign: 'center',
            name: t('trucks.table.comments'),
            value: () => '',
            htmlFor: x => <CommentButton associationKey={`transport-${x.id}`} hideIfNoContent />
        }] : []

    let columns: ColumnDescriptor<TruckTransportItem>[] = [
        {
            name: t('trucks.table.loadingOffloadingDate'), htmlFor: x => (
                <div className={classes.rowFlex}>
                    <div>{x.loadingDate ? moment(x.loadingDate).format('MM/DD') : ''}</div>
                    <div className={classes.separatorArrow}>{'->'}</div>
                    <div><OffloadingField item={x} classes={classes} t={t} /></div>
                </div>)
        },
        { name: t('trucks.table.transportNumber'), value: x => x.truckNumber },
        { name: t('trucks.table.product'), value: x => x.productCode ?? '' },
        {
            name: t('trucks.table.quantity'),
            htmlFor: x => (<div>
                <Typography variant='body2' display='inline'>{tryRoundAndStringify(x.loadedQuantity)}</Typography>
                <Typography variant="caption">
                    {tryRoundAndStringify(x.standardTemperature, true)}
                </Typography>
            </div>),
            total: store.list.listItems.map(x => x.loadedQuantity).reduce((x, acc) => (acc ?? 0) + (x ?? 0), 0),
            totalLabel: t('components.table.total')
        },
        ...observedColumn,
        { name: t('trucks.table.transporter'), value: x => x.transporter },
        {
            name: t('trucks.table.originDestinationSite'), htmlFor: x => (
                <div className={classes.rowFlex}>
                    <div>{x.originSite}</div>
                    <div className={classes.separatorArrow}>{'->'}</div>
                    <div>{x.destinationSite}</div>
                </div>)
        },
        { name: t('trucks.table.border'), hidden: !hasBorderFeature, value: x => x.border },
        { name: t('trucks.table.border2'), hidden: !hasBorder2Feature, value: x => x.border2 },
        { name: t('trucks.table.reference'), value: x => x.reference },
        { name: t('trucks.table.customerSegment'), value: x => x.customerSegment },
        { name: t('trucks.table.status'), htmlFor: x => <TruckStatusIcons item={x} classes={classes} t={t} /> },
        ...commentColumn,
    ]

    let actions: ActionDescriptor<TruckTransportItem>[] = [
        { name: 'Delete', action: store.batchDelete, icon: <DeleteOutlined />, isHidden: !hasClaim(Claims.TruckManager) },
        { name: 'History', action: openHistory, isBodyAction: true, icon: <History /> }
    ]

    return (
        <DataTable
            items={store.list.listItems}
            idSelector={(x: TruckTransportItem) => x.id}
            onClick={openForm}
            columns={columns}
            actions={actions} />
    )
}

function OffloadingField({ item, t, classes }: { item: TruckTransportItem, t: (label: string) => string, classes: any }) {
    let date = item.offloadingDate ? moment(item.offloadingDate).format('MM/DD') : ''

    return item.isOnWarning
        ? (<div className={classes.warning}>{date}
            <Tooltip title={<Typography variant='body1'>{t('trucks.label.warning.offloadingDateWaring')}</Typography>} placement='top'>
                <ErrorOutlineOutlined className={classes.warningIcon} />
            </Tooltip>
        </div>)
        : (<div>{date}</div>)
}

function TruckStatusIcons({ item, t, classes }: { item: TruckTransportItem, t: (label: string) => string, classes: any }) {
    let features = FeatureContainer.useContainer()
    let tradPrefix = 'trucks.form.steps.'

    function getCurrentTruckStatusIndex() { return truckStatuses.findIndex(x => x === item.truckStatus) }
    function getStepTruckStatusIndex(stepStatus: typeof truckStatuses[number]) { return truckStatuses.findIndex(x => x === stepStatus) }

    function isActive(stepStatus: typeof truckStatuses[number]) {
        return getStepTruckStatusIndex(stepStatus) < getCurrentTruckStatusIndex()
    }

    function isCurrent(stepStatus: typeof truckStatuses[number]) {
        return getStepTruckStatusIndex(stepStatus) === getCurrentTruckStatusIndex()
    }

    function getClass(stepStatus: typeof truckStatuses[number]) {
        if (isCurrent(stepStatus))
            return classes.tableStatusIcon + ' ' + classes.currentStatusIcon
        else if (isActive(stepStatus))
            return classes.tableStatusIcon + ' ' + classes.activeStatusIcon
        else
            return classes.tableStatusIcon + ' ' + classes.disabledStatusIcon
    }

    function shouldAddBorderStatus(): boolean {
        if (item.meanOfTransportation == 'Train')
            return features.hasFeature('RailBorder')
        return features.hasFeature('TruckBorder')
    }

    function shouldAddBorder2Status(): boolean {
        if (item.meanOfTransportation == 'Train')
            return features.hasFeature('RailBorder2')
        return features.hasFeature('TruckBorder2')
    }

    return (
        <div className={classes.statusIconsContainer}>
            <Tooltip title={<Typography variant='body1'>{t(tradPrefix + 'loaded')}</Typography>} placement='top'>
                <div className={getClass('loaded')}>
                    {item.meanOfTransportation == 'Train'
                        ? <Train />
                        : <LocalShipping />}
                </div>
            </Tooltip>
            {!item.isOwnCollection ? <>
                {shouldAddBorderStatus() ? <>
                    <Tooltip title={<Typography variant='body1'>{t(tradPrefix + 'arrivedBorder')}</Typography>} placement='top'>
                        <div className={getClass('arrivedBorder')}>
                            <PanTool />
                        </div>
                    </Tooltip>
                    <Tooltip title={<Typography variant='body1'>{t(tradPrefix + 'passedBorder')}</Typography>} placement='top'>
                        <div className={getClass('passedBorder')}>
                            <VerifiedUser />
                        </div>
                    </Tooltip></> : null
                }
                {shouldAddBorder2Status() ? <>
                    <Tooltip title={<Typography variant='body1'>{t(tradPrefix + 'arrivedBorder2')}</Typography>} placement='top'>
                        <div className={getClass('arrivedBorder2')}>
                            <PanTool />
                        </div>
                    </Tooltip>
                    <Tooltip title={<Typography variant='body1'>{t(tradPrefix + 'passedBorder2')}</Typography>} placement='top'>
                        <div className={getClass('passedBorder2')}>
                            <VerifiedUser />
                        </div>
                    </Tooltip></> : null
                }
                <Tooltip title={<Typography variant='body1'>{t(tradPrefix + 'arrived')}</Typography>} placement='top'>
                    <div className={getClass('arrived')}>
                        <MyLocation />
                    </div>
                </Tooltip>
                <Tooltip title={<Typography variant='body1'>{t(tradPrefix + 'offloaded')}</Typography>} placement='top'>
                    <div className={getClass('offloaded')}>
                        <CheckCircle />
                    </div>
                </Tooltip></> : null
            }
        </div >)
}

let styles = _ => createStyles(style)

export default withStyles(styles, muiOptions)(TruckTable)