import React, { useState, useEffect } from 'react'
import { Switch } from '../../../../common/customComponents'
import { t } from '../../../../../infrastructure/i18nextHelper'
import { hasFeature } from '../../../../../infrastructure/feature'
import { StockMovementContainer } from '../../stockMovementStore'
import { MovementType } from '../../../stockModels'
import { DirectTransfer, TransferFlowType, StockTransferOrder } from '../../../../../app/common/sapHelper'

type DirectMovementSwitchProps = {
    isMainSapFlow: boolean
    className: any
}

//Tag: Custom
export function DirectMovementSwitch({ isMainSapFlow, className }: DirectMovementSwitchProps) {
    let store = StockMovementContainer.useContainer()
    let sapFlow = store.getSapFlow(isMainSapFlow)

    let show = sapFlow.movementType === MovementType.Transfer
        || sapFlow.movementType === MovementType.StatusChange

    if (!show || !hasFeature('SapDirectTransfer')) return (<></>)

    let sapFlowType = useSapFlowType(isMainSapFlow)

    return (
        <div className={className ?? ''}>
            <Switch
                form
                changeCallback={() => sapFlowType.setValue(sapFlowType.value === DirectTransfer.sapFlowType ? StockTransferOrder.sapFlowType : DirectTransfer.sapFlowType)}
                isChecked={sapFlowType.value === DirectTransfer.sapFlowType}
                offText={t(`stock.label.movement.sapAction.stoTransferSwitchLabel`)}
                onText={t(`stock.label.movement.sapAction.directTransferSwitchLabel`)} />
        </div>
    )
}

function useSapFlowType(isMainSapFlow: boolean) {
    let store = StockMovementContainer.useContainer()
    let sapFlow = store.getSapFlow(isMainSapFlow)

    let defaultSapFlowType = () =>
        sapFlow.sapFlowType !== null
            ? sapFlow.sapFlowType
            : !hasFeature('TransferIsStoDefault') ? DirectTransfer.sapFlowType : StockTransferOrder.sapFlowType

    let [value, setValue] = useState<TransferFlowType | null>(defaultSapFlowType())

    useEffect(onPlantChange, [store.stockMovement.mainSapFlow.originPlant, store.stockMovement.secondSapFlow?.originPlant])
    useEffect(updateSapFlowType, [value])

    function onPlantChange() {
        let isConcerned = hasFeature('SapDirectTransfer') && sapFlow.sapFlowType == null &&
            (sapFlow.movementType == MovementType.Transfer || sapFlow.movementType == MovementType.StatusChange)

        if (!isConcerned) return

        let newFlow: TransferFlowType =
            !hasFeature('TransferIsStoDefault') ? DirectTransfer.sapFlowType! :
                sapFlow.originPlant?.startsWith('W')
                    ? DirectTransfer.sapFlowType! : StockTransferOrder.sapFlowType!

        store.setSapFlow(isMainSapFlow, { ...sapFlow, sapFlowType: newFlow })
    }

    function updateSapFlowType() {
        store.setSapFlow(isMainSapFlow, { ...sapFlow, sapFlowType: value })
    }

    return { value, setValue }
}