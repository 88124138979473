import { Guid } from '@guid'

export type TruckTransportFormModel = {
    id?: Guid
    meanOfTransportation?: string | null,
    productId?: string | null
    originSite?: string | null
    destinationSite?: string | null
    company?: string | null
    reference?: string | null
    transportNumber?: string | null
    transporter?: string | null
    exitNumber?: string | null
    border?: string | null
    border2?: string | null
    loadingDate?: DateString | null
    loadedQuantity?: number | null
    expectedOffloadingDate?: Date | null
    offloadedQuantity?: number | null
    quantityUnit?: string | null
    arrivedBorderDate?: DateString | null
    crossingBorderDate?: DateString | null
    arrivedBorder2Date?: DateString | null
    crossingBorder2Date?: DateString | null
    arrivalDate?: DateString | null
    offloadingDate?: DateString | null
    dutyStatus?: string | null
    driver?: string | null
    truckStatus?: typeof truckStatuses[number] | null
    customerSegment?: string | null
    counterpartyId?: Guid | null
    isOwnCollection?: boolean | null
    movementId?: Guid | null
    entrys: TruckTransportEntryFormModel[]
    version?: string
    purchaseNumber?: string | null,
    loadedObservedTemperature?: number | null,
    loadedObservedQuantity?: number | null,
    offloadedObservedTemperature?: number | null,
    offloadedObservedQuantity?: number | null,
    erbNumber?: string | null,
    customsReference?: string | null,
    fuelLevy?: number | null,
    customsDuty?: number | null,
    cedFees?: number | null,
    importVAT?: number | null,
    billOfLadingNumber?: string | null,
    carrierDeliveryNote?: string | null,
    density?: number | null,
    loadingSealNumber?: string | null,
    offloadingSealNumber?: string | null,
}

export type TruckTransportEntryFormModel = {
    id: Guid
    quantity?: number | null
    entryNumber?: string | null
}

export type Product = {
    id: Guid
    code: string
    order: number
    transportUnit: string
    productGroup: string | null
}

export type Filters = {
    startPeriod: string | null
    endPeriod: string | null
    inTransitAt: string | null
    meanOfTransportation: string | null
    productIds: Guid[] | null
    originSites: string[] | null
    destinationSites: string[] | null
    customerSegments: string[] | null
    query: string | null
    inTransit: boolean,
    truckStatuses: TruckStatus[]
}

export type Site = {
    code: string
    name: string
    siteGroup: string | null
}

export type Company = {
    code: string
    name: string
    order: number
}

export type Counterparty = {
    id: string
    name: string
}

export type ChannelCustomerSegment = {
    company: string
    channel: string
    customerSegment: string
    dutyStatus: string
    order: number
}

export type TruckTransportItem = {
    id: Guid
    loadingDate?: DateString
    arrivedBorderDate?: DateString
    crossingBorderDate?: DateString
    arrivedBorder2Date?: DateString
    crossingBorder2Date?: DateString
    arrivalDate?: DateString
    offloadingDate?: DateString | null
    truckNumber: string
    productCode?: string
    transporter: string
    originSite: string
    destinationSite: string | null
    border: string | null
    border2: string | null
    customerSegment: string | null
    reference: string | null
    loadedQuantity?: number
    offloadedQuantity?: number | null
    isOnWarning: boolean
    isOwnCollection: boolean
    truckStatus?: typeof truckStatuses[number] | null
    version: string
    loadedObservedTemperature: number | null
    loadedObservedQuantity: number | null
    standardTemperature: number | null
    meanOfTransportation: string
}

export type SupplyRequestListItem = {
    id: Guid
    canEdit: boolean
    date: Date
    arrivals: SupplyArrival
    departure: SupplyDeparture
    sale: number | null
    stock: number | null
}

export type SupplyArrival = {
    forecast: number | null
    nominated: number | null
    confirmed: number | null
    transit: number | null
    actual: number | null
}

export type SupplyDeparture = {
    values: SupplyDepartureSites
    status: DepartureStatus
}

export type SupplyDepartureSites = {
    [site: string]: SupplyDepartureSite
}

export type SupplyDepartureSite = {
    volume: (number | null)
    status: DepartureStatus
}

export type DepartureStatus = 'ToRequest' | 'Confirmed' | 'Loaded' | 'ToPlan'

export const truckStatusesWith1Border = ['loaded', 'arrivedBorder', 'passedBorder', 'arrived', 'offloaded'] as const
export const truckStatuses = ['loaded', 'arrivedBorder', 'passedBorder', 'arrivedBorder2', 'passedBorder2', 'arrived', 'offloaded'] as const
export const truckStatusesNoBorder = ['loaded', 'arrived', 'offloaded'] as const

export type TruckStatus = TruckTransportFormModel['truckStatus']
export type TruckStep = typeof truckStatuses[number]

export type DateString = string
