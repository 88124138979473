import React, { useState, useEffect } from 'react'
import { defaultStyles, muiOptions, MuiProps, defaultColors } from '../../infrastructure/materialUiThemeProvider'
import { withStyles, createStyles, Paper, Input, Typography } from '@material-ui/core'
import { api, get, post } from '../../infrastructure/api'
import { t } from '../../infrastructure/i18nextHelper'
import { Button, DatePicker, MultipleSelect, LabelButton } from '../common/customComponents'
import { HelpButton } from '../common/components/helpButton'
import { snackbars } from '../../infrastructure/snackbars'
import { hasClaim } from '../../infrastructure/signIn/userContext'
import { Claims } from '../../infrastructure/signIn/models'
import { hasFeature } from '../../infrastructure/feature'

function RefreshDataAdmin({ classes }: MuiProps) {
    return (
        <div className={classes.container}>
            <RefreshVessel classes={classes} />
            <RefreshDeal classes={classes} />
            <RefreshMktSaleDeal classes={classes} />
            <ImportQuotation classes={classes} />
            <ImportArrados classes={classes} />
            <ProcessMessages classes={classes} />
            <ReplayMessages classes={classes} />
            {hasFeature('ImportSam')
                && hasClaim(Claims.ImportSamManager)
                && <ImportSam classes={classes} />}
        </div>)
}

function RefreshVessel({ classes }: MuiProps) {
    let [date, setDate] = useState<string | null>(null)
    let [selectedCountrys, setSelectedCountrys] = useState<string[]>([])
    let [availableUserCountrys, setAvailableUserCountrys] = useState<string[]>([])
    let [canSave, setCanSave] = useState(false)

    useEffect(() => {
        !!selectedCountrys && !!date ? setCanSave(true) : setCanSave(false)
    }, [selectedCountrys, date])

    useEffect(() => { setAvailableCountrys() }, [])

    let setAvailableCountrys = async () => {
        let userCountrys = await get<string[]>('auth/country')
        setAvailableUserCountrys(userCountrys)
    }

    function refreshVessel() {
        selectedCountrys.forEach(async x => {
            await post("vessel/refreshData", { startDate: date }, { countryOverride: x })
        })
    }

    return (
        <Paper className={classes.paper}>
            <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                {t('admin.refreshData.vessel.name')}
            </Typography>
            <div className={classes.fieldsContainer}>
                <div className={classes.inputsContainer}>
                    <Button
                        className={classes.refreshButton}
                        disabled={!canSave}
                        label={t('admin.refreshData.vessel.refreshButton')}
                        onClick={refreshVessel} />
                    <HelpButton title={t('admin.refreshData.vessel.helpTitle')} text={t('admin.refreshData.vessel.help')} onClick={() => { }} />
                </div>

                <div className={classes.inputsContainer}>
                    <DatePicker date={date} label={t('admin.refreshData.vessel.date')}
                        setDate={newDate => setDate(newDate)} />
                    <MultipleSelect label={t('admin.refreshData.vessel.countrys')}
                        values={selectedCountrys}
                        choices={availableUserCountrys.map(x => ({ value: x, text: t(`country.${x}`) }))}
                        onChange={x => setSelectedCountrys(x)} />
                </div>
            </div>
        </Paper>
    )
}

function RefreshMktSaleDeal({ classes }: MuiProps) {
    let refreshMktSalesDeals = async () => {
        await api.post('deal/mktSalesDeals', { allCountry: true })
        snackbars.success('Operation completed')
    }

    return (
        <Paper className={classes.paper}>
            <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                {t('admin.refreshData.mktSaleDeal.name')}
            </Typography>

            <div className={classes.fieldsContainer}>
                <div className={classes.inputsContainer}>
                    <Button
                        className={classes.refreshButton}
                        label={t('admin.refreshData.mktSaleDeal.refreshButton')}
                        onClick={refreshMktSalesDeals} />
                    <HelpButton title={t('admin.refreshData.mktSaleDeal.helpTitle')} text={t('admin.refreshData.mktSaleDeal.help')} onClick={() => { }} />
                </div>
            </div>
        </Paper>
    )
}

function RefreshDeal({ classes }: MuiProps) {
    let [date, setDate] = useState<string | null>(null)
    let [selectedCountrys, setSelectedCountrys] = useState<string[]>([])
    let [availableUserCountrys, setAvailableUserCountrys] = useState<string[]>([])
    let [canSave, setCanSave] = useState(false)

    useEffect(() => {
        !!selectedCountrys && !!date ? setCanSave(true) : setCanSave(false)
    }, [selectedCountrys, date])

    useEffect(() => { setAvailableCountrys() }, [])

    let setAvailableCountrys = async () => {
        let userCountrys = await get<string[]>('auth/country')
        setAvailableUserCountrys(userCountrys)
    }

    function refreshDeal() {
        selectedCountrys.forEach(async x => {
            await post("deal/refreshData", { startDate: date }, { countryOverride: x })
        })
    }

    return (
        <Paper className={classes.paper}>
            <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                {t('admin.refreshData.deal.name')}
            </Typography>
            <div className={classes.fieldsContainer}>
                <div className={classes.inputsContainer}>
                    <Button
                        className={classes.refreshButton}
                        disabled={!canSave}
                        label={t('admin.refreshData.deal.refreshButton')}
                        onClick={refreshDeal} />
                    <HelpButton title={t('admin.refreshData.deal.helpTitle')} text={t('admin.refreshData.deal.help')} onClick={() => { }} />
                </div>

                <div className={classes.inputsContainer}>
                    <DatePicker date={date} label={t('admin.refreshData.deal.date')}
                        setDate={newDate => setDate(newDate)} />
                    <MultipleSelect label={t('admin.refreshData.deal.countrys')}
                        values={selectedCountrys}
                        choices={availableUserCountrys.map(x => ({ value: x, text: t(`country.${x}`) }))}
                        onChange={x => setSelectedCountrys(x)} />
                </div>
            </div>
        </Paper>
    )
}

function ImportQuotation({ classes }: MuiProps) {
    let [date, setDate] = useState<string | null>(null)
    let [canSave, setCanSave] = useState(false)

    useEffect(() => {
        !!date ? setCanSave(true) : setCanSave(false)
    }, [date])

    async function importQuotations() {
        await post("pricing/quotation/import", { dates: [date] })
        snackbars.success(t('admin.refreshData.quotations.successImport'))
    }

    return (
        <Paper className={classes.paper}>
            <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                {t('admin.refreshData.quotations.name')}
            </Typography>
            <div className={classes.fieldsContainer}>
                <div className={classes.inputsContainer}>
                    <Button
                        className={classes.refreshButton}
                        disabled={!canSave}
                        onClick={importQuotations}
                        label={t('admin.refreshData.quotations.importButton')} />
                    <HelpButton title={t('admin.refreshData.quotations.helpTitle')} text={t('admin.refreshData.quotations.help')} onClick={() => { }} />
                </div>

                <div className={classes.inputsContainer}>
                    <DatePicker date={date} label={t('admin.refreshData.quotations.date')}
                        setDate={newDate => setDate(newDate)} />
                </div>
            </div>
        </Paper>
    )
}

function ProcessMessages({ classes }: MuiProps) {
    async function processMessages() {
        await post("message/process", {})
        snackbars.success(t('admin.refreshData.processMessages.successImport'))
    }

    return (
        <Paper className={classes.paper}>
            <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                {t('admin.refreshData.processMessages.name')}
            </Typography>
            <div className={classes.fieldsContainer}>
                <div className={classes.inputsContainer}>
                    <Button
                        className={classes.refreshButton}
                        onClick={processMessages}
                        label={t('admin.refreshData.processMessages.importButton')} />
                    <HelpButton title={t('admin.refreshData.processMessages.helpTitle')} text={t('admin.refreshData.processMessages.help')} onClick={() => { }} />
                </div>
            </div>
        </Paper>
    )
}

function ReplayMessages({ classes }: MuiProps) {
    let [date, setDate] = useState<string | null>(null)
    async function processMessages() {
        await post("message/replay", { fromDate: date })
        snackbars.success(t('admin.refreshData.replayMessages.successImport'))
    }

    return (
        <Paper className={classes.paper}>
            <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                {t('admin.refreshData.replayMessages.name')}
            </Typography>
            <div className={classes.fieldsContainer}>
                <div className={classes.inputsContainer}>
                    <Button
                        className={classes.refreshButton}
                        onClick={processMessages}
                        label={t('admin.refreshData.replayMessages.importButton')} />
                    <HelpButton title={t('admin.refreshData.replayMessages.helpTitle')} text={t('admin.refreshData.replayMessages.help')} onClick={() => { }} />
                </div>
                <div className={classes.inputsContainer}>
                    <DatePicker date={date} label={t('admin.refreshData.deal.date')}
                        setDate={newDate => setDate(newDate)} />
                </div>
            </div>
        </Paper>
    )
}

type Company = {
    name: string
    code: string
}
function ImportArrados({ classes }: MuiProps) {
    let [companys, setCompanys] = useState<Company[]>([])
    let [selectedCompanys, setSelectedCompanys] = useState<string[]>([])
    let [canSave, setCanSave] = useState(false)

    useEffect(() => {
        setCanSave(selectedCompanys.length > 0)
    }, [selectedCompanys])

    useEffect(() => {
        getCompanys()
    }, [])

    let getCompanys = async () => {
        let companys = await get<Company[]>('stock/movement/mktsale/company')
        setCompanys(companys)
    }

    async function importArrados() {
        for (let company of selectedCompanys) {
            await post("stock/movement/mktsale/import/arados", { company })
            snackbars.success(t('admin.refreshData.arrados.importSuccessful', { company }))
        }
    }

    return (
        <Paper className={classes.paper}>
            <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                {t('admin.refreshData.arrados.name')}
            </Typography>
            <div className={classes.fieldsContainer}>
                <div className={classes.inputsContainer}>
                    <Button
                        className={classes.refreshButton}
                        disabled={!canSave}
                        label={t('admin.refreshData.arrados.importButton')}
                        onClick={importArrados} />
                    <HelpButton title={t('admin.refreshData.arrados.helpTitle')} text={t('admin.refreshData.arrados.help')} onClick={() => { }} />
                </div>

                <div className={classes.inputsContainer}>
                    {<MultipleSelect label={t('admin.refreshData.arrados.companys')}
                        values={selectedCompanys}
                        choices={companys.map(x => ({ value: x.code, text: x.name }))}
                        onChange={x => setSelectedCompanys(x)} />}
                </div>
            </div>
        </Paper>
    )
}

function ImportSam({ classes }: MuiProps) {
    let uploadFile = async (url: string, e) =>
        await api.upload(url, e.target.files[0], 'import')

    let uploadTruckLoading = async (e) => {
        await uploadFile('truck/import/samTruckLoading', e)
        snackbars.success(t('admin.refreshData.importSam.importSuccessful', { type: 'GTY' }))
    }

    let uploadRbr = async (e) => {
        await uploadFile('truck/import/samRbr', e)
        snackbars.success(t('admin.refreshData.importSam.importSuccessful', { type: 'RBR' }))
    }

    let uploadRailCarLoading = async (e) => {
        await uploadFile('truck/import/samTco', e)
        snackbars.success(t('admin.refreshData.importSam.importSuccessful', { type: 'TCO' }))
    }

    return (
        <Paper className={classes.paper}>
            <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                {t('admin.refreshData.importSam.name')}
            </Typography>
            <div className={classes.fieldsContainer}>
                <div className={classes.inputsContainer}>
                    <LabelButton
                        className={classes.refreshButton}
                        disabled={false}
                        label={t('admin.refreshData.importSam.importButton', { type: 'GTY' })} >
                        <Input style={{ display: 'none' }} type='file'
                            onChange={e => { uploadTruckLoading?.(e); e.target.value = ""; }} />
                    </LabelButton>
                    <HelpButton title={t('admin.refreshData.importSam.helpTitle')} text={t('admin.refreshData.importSam.help', { type: 'GTY' })} />
                    <LabelButton
                        className={classes.rbrButton}
                        disabled={false}
                        label={t('admin.refreshData.importSam.importButton', { type: 'RBR' })} >
                        <Input style={{ display: 'none' }} type='file'
                            onChange={e => { uploadRbr?.(e); e.target.value = ""; }} />
                    </LabelButton>
                    <HelpButton title={t('admin.refreshData.importSam.helpTitle')} text={t('admin.refreshData.importSam.help', { type: 'RBR' })} />
                    <LabelButton
                        className={classes.rbrButton}
                        disabled={false}
                        label={t('admin.refreshData.importSam.importButton', { type: 'TCO' })} >
                        <Input style={{ display: 'none' }} type='file'
                            onChange={e => { uploadRailCarLoading?.(e); e.target.value = ""; }} />
                    </LabelButton>
                    <HelpButton title={t('admin.refreshData.importSam.helpTitle')} text={t('admin.refreshData.importSam.help', { type: 'TCO' })} />
                </div>
            </div>
        </Paper>
    )
}

let styles = theme =>
    createStyles({
        paper: {
            width: '100%',
            padding: '1em',
            marginBottom: '1em'
        },
        container: {
            ...defaultStyles.flexColumn,
            marginTop: '2em'
        },
        field: {
            marginTop: "0.5em",
            marginBottom: "0.5em"
        },
        fieldsContainer: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between'
        },
        inputsContainer: {
            ...defaultStyles.flexRow,
        },
        refreshButton: {
            ...defaultStyles.secondaryButton,
            marginRight: '1em'
        },
        paperTitle: {
            color: defaultColors.red.main.color,
            marginLeft: "1em"
        },
        rbrButton: {
            ...defaultStyles.secondaryButton,
            marginRight: '1em',
            marginLeft: '1em'
        }
    })

export default withStyles(styles, muiOptions)(RefreshDataAdmin)