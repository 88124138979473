import { useEffect, useMemo, useState } from "react"
import moment from "moment"
import { createContainer } from "unstated-next"
import { Company, Site, StockProduct } from "../stockModels"
import { setInLocalStorage } from "../../../infrastructure/localStorage"
import { Product, SupplyBalanceFilter } from "../../supplyBalance/supplyBalanceModels"
import { compareAndFixPeriod } from "../../common/components/datePicker/tools"
import { useFilter, mapValues } from "../../common/useFilter"

const defaultStartDate = () => moment.utc().startOf('month').format()
const defaultEndDate = () => moment.utc().startOf('month').add(1, 'M').add(-1, 'day').format()
const todayDate = () => moment.utc().format()

export function useStockBoardMultiFilters() {
    let companies = useFilter<Company>(c => c.map(x => ({ value: x.code, text: x.name })))
    let dutyStatuses = useFilter<string>(x => x)
    let sites = useFilter<Site>(s => s.map(x => ({ value: x.code, text: x.name, group: x.siteGroup })))
    let types = useFilter<string>(x => x)
    let productIds = useFilter<StockProduct | Product>(p => p.map(x => ({ value: x.id, text: x.code, group: x.productGroup ?? x.code })))

    let values = useMemo(() => {
        return mapValues({ companies, dutyStatuses, sites, types, productIds })
    }, [
        companies.selectedChoices,
        dutyStatuses.selectedChoices,
        sites.selectedChoices,
        types.selectedChoices,
        productIds.selectedChoices])

    return { companies, dutyStatuses, sites, types, productIds, values }
}

function useStockBoardFilters() {

    let multiSelectFilters = useStockBoardMultiFilters()

    let [start, setStart] = useState<string | null>(null)
    let [end, setEnd] = useState<string | null>(null)
    let [monthRange, setMonthRange] = useState<number>(2)
    let [resellersRestrictionPercentage, setResellersRestrictionPercentage] = useState<number | null>(null)
    let [resellersRestrictionStart, setResellersRestrictionStart] = useState<string | null>(null)
    let [resellersRestrictionEnd, setResellersRestrictionEnd] = useState<string | null>(null)
    let [stockPercentHigh, setStockPercentHigh] = useState<boolean>(false)
    let [usefulStock, setUsefulStock] = useState<boolean>(false)

    let values = {
        ...multiSelectFilters.values,
        start,
        end,
        monthRange,
        resellersRestrictionPercentage,
        resellersRestrictionStart,
        resellersRestrictionEnd,
        stockPercentHigh,
        usefulStock
    }

    return {
        values,
        companiesFilter: multiSelectFilters.companies, companies: multiSelectFilters.values.companies,
        dutyStatusesFilter: multiSelectFilters.dutyStatuses, dutyStatuses: multiSelectFilters.values.dutyStatuses,
        sitesFilter: multiSelectFilters.sites, sites: multiSelectFilters.values.sites,
        typesFilter: multiSelectFilters.types, types: multiSelectFilters.values.types,
        productIdsFilter: multiSelectFilters.productIds, productIds: multiSelectFilters.values.productIds,
        start, setStart,
        end, setEnd,
        monthRange, setMonthRange,
        resellersRestrictionPercentage, setResellersRestrictionPercentage,
        resellersRestrictionStart, setResellersRestrictionStart,
        resellersRestrictionEnd, setResellersRestrictionEnd,
        stockPercentHigh, setStockPercentHigh,
        usefulStock, setUsefulStock
    }
}

function useStockFilters() {
    let filters = useStockBoardFilters()

    let [filtersInitialized, setFiltersInitialized] = useState<boolean>(false)

    useEffect(() => {
        if (filtersInitialized)
            setInLocalStorage('filters', {
                companies: filters.companiesFilter.selectedChoices,
                dutyStatuses: filters.dutyStatusesFilter.selectedChoices,
                end: filters.end,
                monthRange: filters.monthRange,
                productIds: filters.productIdsFilter.selectedChoices,
                resellersRestrictionEnd: filters.resellersRestrictionEnd,
                resellersRestrictionPercentage: filters.resellersRestrictionPercentage,
                resellersRestrictionStart: filters.resellersRestrictionStart,
                sites: filters.sitesFilter.selectedChoices,
                start: filters.start,
                stockPercentHigh: filters.stockPercentHigh,
                types: filters.typesFilter.selectedChoices,
                usefulStock: filters.usefulStock
            })
    }, [filters.values])

    let GetSelectedItems = () => {
        let localStorageFilters = localStorage.getItem('filters')

        if (localStorageFilters) {
            let filterObj = JSON.parse(localStorageFilters)
            let filtersLength = Object.keys(filterObj).length

            if (filtersLength === 0) return

            let parseArray = x => Array.isArray(x) ? x : null

            const selectedDutyStatuses = parseArray(filterObj.dutyStatuses) ?? []
            filters.dutyStatusesFilter.setSelectedChoices(selectedDutyStatuses)

            const selectedCompanies = parseArray(filterObj.companies) ?? []
            filters.companiesFilter.setSelectedChoices(selectedCompanies)

            const selectedProducts = parseArray(filterObj.productIds) ?? []
            filters.productIdsFilter.setSelectedChoices(selectedProducts)

            const selectedSites = parseArray(filterObj.sites) ?? []
            filters.sitesFilter.setSelectedChoices(selectedSites)

            const selectedTypes = parseArray(filterObj.types) ?? []
            filters.typesFilter.setSelectedChoices(selectedTypes)

            filters.setStart(filterObj.start)
            filters.setEnd(filterObj.end)
            filters.setMonthRange(filterObj.monthRange)
            filters.setResellersRestrictionPercentage(filterObj.resellersRestrictionPercentage)
            filters.setResellersRestrictionStart(filterObj.resellersRestrictionStart)
            filters.setResellersRestrictionEnd(filterObj.resellersRestrictionEnd)
            filters.setStockPercentHigh(filterObj.stockPercentHigh)
            filters.setUsefulStock(filterObj.usefulStock)
        } else {
            filters.setStart(defaultStartDate())
            filters.setEnd(defaultEndDate())
            filters.setResellersRestrictionStart(todayDate())
            filters.setResellersRestrictionEnd(defaultEndDate())
        }
    }

    let setDefaultFiltersValues = (products: StockProduct[] | Product[], sites: Site[], companies: Company[], dutyStatuses: string[]) => {
        if (dutyStatuses.length > 0) {
            filters.dutyStatusesFilter.setItems(dutyStatuses)
        }

        if (companies.length > 0) {
            filters.companiesFilter.setItems(companies)
        }

        if (products.length > 0) {
            filters.productIdsFilter.setItems(products)
        }

        if (sites.length > 0) {
            filters.sitesFilter.setItems(sites.sort((a, b) => a.name.localeCompare(b.name)))
        }

        filters.typesFilter.setItems(['Purchase', 'MktSale', 'Untriggered'])

        GetSelectedItems()
    }

    let loadFiltersElements = async (products: StockProduct[] | Product[], sites: Site[], companies: Company[]) => {
        if (filters?.dutyStatuses.length > 0) return

        let dutyStatuses = companies.flatMap(x => x.dutyStatuses).distinct()
        setDefaultFiltersValues(products, sites, companies, dutyStatuses)
        setFiltersInitialized(true)
    }

    let impactFilters = (newFilters: SupplyBalanceFilter) => {
        filters.sitesFilter.setSelectedChoices(newFilters.sites)
        filters.productIdsFilter.setSelectedChoices(newFilters.productIds)
        filters.dutyStatusesFilter.setSelectedChoices(newFilters.dutyStatuses)
        filters.companiesFilter.setSelectedChoices(newFilters.companies)
        filters.setUsefulStock(newFilters.usefulStock)
    }

    let changePeriod = (period) => {
        let newPeriod = compareAndFixPeriod(
            filters.values.start,
            period.startDate,
            filters.values.end,
            period.endDate
        )
        filters.setStart(newPeriod.start)
        filters.setEnd(newPeriod.end)
    }

    return {
        filters, filtersInitialized,
        loadFiltersElements, impactFilters,
        changePeriod
    }
}
export let StockFiltersContainer = createContainer(useStockFilters)