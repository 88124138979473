import React, { useState } from 'react'
import { Select as MuiSelect, MenuItem, Checkbox, ListItemText, Chip } from '@material-ui/core'
import { t } from '../../../../infrastructure/i18nextHelper'
import { CustomSelectWrapper, CustomSelectProps } from './customSelectWrapper'
import { CustomSelectStyleProps, parseClassNames } from './select'
import { formatChoices } from './utils'
import { Item } from './types'
import { Filter } from '../../useFilter'

type MultipleSelectProps = {
    choices?: Item[] | string[]
    values?: any
    disableNewStyle?: boolean,
    chips?: boolean,
    allWhenEmpty?: boolean
    filter?: Filter
} & CustomSelectProps & CustomSelectStyleProps

export function MultipleSelect(props: MultipleSelectProps) {
    let [isEmpty, setIsEmpty] = useState<boolean>(false)
    let values = (!isEmpty && props.values ? props.values : props.filter?.selectedChoices) ?? []
    let choices = props.choices ?? props.filter?.choices ?? []
    let choicesItems = formatChoices(choices)

    let variant = !props.disableNewStyle ? { variant: 'filled' } as any : {}

    return (
        <CustomSelectWrapper label={props.label} classNames={parseClassNames({ ...props, choices: choices })}>
            <MuiSelect
                id={props.id ?? ''}
                className={props.classesOverride?.select ?? props.classes.defaultSelect}
                disabled={props.disabled}
                multiple
                {...variant}
                value={values ?? []}
                onChange={e => onChange(e.target.value as string[])}
                MenuProps={{ className: props.classes.noDrag }}
                renderValue={renderValue}
                displayEmpty={true}>
                {choicesItems.map((x, i) => (
                    <MenuItem className={props.classes.menuItem} key={x.value} value={x.value}>
                        <Checkbox checked={!!values ? values.indexOf(x.value) > -1 : false} />
                        <ListItemText primary={x.text} />
                    </MenuItem>
                ))}
            </MuiSelect>
        </CustomSelectWrapper>
    )

    function renderValue(selectedVals: string[]) {
        let hasNoValue = !selectedVals || selectedVals.length === 0
        let hasAllValues = (selectedVals.length === props.choices?.length && props.choices.length > 2)
            || (selectedVals.length === props.filter?.choices?.length && props.filter?.choices.length > 2)
        let selectedItems = choicesItems.filter(x => (selectedVals as string[]).findIndex(y => y == x.value) > -1).map(x => x.text)

        if (props.chips)
            return (<div className={props.classes.chipsContainer}>{selectedItems.map(value => (<Chip key={value} label={value} />))}</div>)
        return hasNoValue || hasAllValues
            ? t('components.allFiltersLine')
            : choicesItems.filter(x => (selectedVals as string[]).findIndex(y => y == x.value) > -1).map(x => x.text).join(', ')
    }

    function onChange(selectedVals: string[]) {
        if (selectedVals.length === 0) {
            setIsEmpty(true)
            if (props.allWhenEmpty)
                selectedVals = (props.choices ?? props.filter?.choices ?? []).map(x => x.value)
        } else if (isEmpty && selectedVals.length > 0)
            setIsEmpty(false)

        if (props.onChange)
            props.onChange(selectedVals)
        else if (props.filter)
            props.filter.setSelectedChoices(selectedVals)
    }
}