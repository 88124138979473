import { createStyles, withStyles } from '@material-ui/core'
import React from 'react'
import { FeatureContainer } from '../../../../../../infrastructure/feature'
import { muiOptions } from '../../../../../../infrastructure/materialUiThemeProvider'
import { SapButton } from '../../../../../../infrastructure/sap/components/sapButton'
import { MovementType } from '../../../../stockModels'
import { StockMovementContainer } from '../../../stockMovementStore'
import { getSapLabel, getSapTooltip, highlightRequiredFields, isEnabled, isValidated, isDisplayable } from './formRequirements'
import { handleSapCall } from '../sapHandlers'
import {
    DirectTransfer, Gains, GoodReceipt, GoodsTransfer, Losses, PurchaseOrder, PurchaseRelease, Rebranding, SaleDeliveryNote,
    SaleGoodsIssue, SalesOrder, SapTypes, TransferDeliveryNote, TransferGoodsIssue, StockTransferOrder, SapFlowStep
} from '../../../../../../app/common/sapHelper'

export type ButtonsProps = {
    className?: string
    classes: any
    isMainSapFlow: boolean
}

function _CreateSapMovementButtons({ className, classes, isMainSapFlow }: ButtonsProps) {
    let store = StockMovementContainer.useContainer()
    let sapFlow = store.getSapFlow(isMainSapFlow)

    const allowedMovementTypes = [
        MovementType.Sale,
        MovementType.Transfer,
        MovementType.StatusChange,
        MovementType.Rebranding,
        MovementType.Purchase,
        MovementType.Gains,
        MovementType.Losses
    ]

    if (sapFlow.movementType == null || allowedMovementTypes.indexOf(sapFlow.movementType) === -1)
        return (<></>)

    let featureContainer = FeatureContainer.useContainer()
    let buttonOptions = getButtonOptions()

    return (
        <div className={`${classes.buttonsContainer} ${className || ''}`}>
            {buttonOptions
                .filter(option =>
                    option.movementTypes.includes(sapFlow.movementType!) &&
                    (!option.sapFlowStep.sapFlowType || option.sapFlowStep.sapFlowType === sapFlow.sapFlowType))
                .filter(option => isDisplayable(option.sapFlowStep.sapType, featureContainer))
                .map(option => (
                    <CreateButton key={option.sapFlowStep.sapType}
                        type={option.sapFlowStep.sapType} isMainSapFlow={isMainSapFlow} />
                ))}
        </div>
    )
}

export function shouldRetryReleaseValidation(type: SapTypes): boolean {
    return type === PurchaseOrder.sapType || type === PurchaseRelease.sapType
}

type CreateButtonProps = {
    type: SapTypes
    isMainSapFlow: boolean
}

function CreateButton({ type, isMainSapFlow }: CreateButtonProps) {
    let store = StockMovementContainer.useContainer()
    let onClick = async () => {
        await store.trySave()
        await handleSapCall(type, store.stockMovement, async () => { await store.load(store.stockMovement.id) })
    }

    let sapFlow = store.getSapFlow(isMainSapFlow)

    return (
        <SapButton
            label={getSapLabel(type, sapFlow)}
            tooltip={getSapTooltip(type, sapFlow)}
            onHelpClick={() => highlightRequiredFields(type, isMainSapFlow, store)}
            onClick={onClick}
            validated={isValidated(type, sapFlow)}
            disabled={!isEnabled(type, store.stockMovement, sapFlow, !!store.associatedDeal)} />
    )
}

const getButtonOptions: () => ButtonOption[] = () => [
    { movementTypes: [MovementType.Sale], sapFlowStep: SalesOrder },
    { movementTypes: [MovementType.Sale], sapFlowStep: SaleDeliveryNote },
    { movementTypes: [MovementType.Sale], sapFlowStep: SaleGoodsIssue },
    { movementTypes: [MovementType.Purchase], sapFlowStep: PurchaseOrder },
    { movementTypes: [MovementType.Purchase], sapFlowStep: PurchaseRelease },
    { movementTypes: [MovementType.Rebranding], sapFlowStep: Rebranding },
    { movementTypes: [MovementType.Gains], sapFlowStep: Gains },
    { movementTypes: [MovementType.Losses], sapFlowStep: Losses },
    { movementTypes: [MovementType.Transfer, MovementType.StatusChange], sapFlowStep: StockTransferOrder },
    { movementTypes: [MovementType.Transfer, MovementType.StatusChange], sapFlowStep: TransferDeliveryNote },
    { movementTypes: [MovementType.Transfer, MovementType.StatusChange], sapFlowStep: TransferGoodsIssue },
    { movementTypes: [MovementType.Transfer, MovementType.StatusChange], sapFlowStep: GoodsTransfer },
    { movementTypes: [MovementType.Transfer, MovementType.StatusChange], sapFlowStep: DirectTransfer },
    { movementTypes: [MovementType.Purchase], sapFlowStep: GoodReceipt },
]

type ButtonOption = {
    movementTypes: MovementType[]
    sapFlowStep: SapFlowStep
}

let styles = _ =>
    createStyles({
        buttonsContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
        }
    })

export let CreateSapMovementButtons = withStyles(styles, muiOptions)(_CreateSapMovementButtons)