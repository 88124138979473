import React, { useEffect, useRef, useState, useImperativeHandle, RefObject } from 'react'
import { withStyles, createStyles, Typography, Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core'
import { NumberField, Button, DateRangePicker } from '../../common/customComponents'
import { muiOptions, MuiProps, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import moment from 'moment'
import { StockFiltersContainer } from './filtersStore'

function _ResellerRestrictionFilter({ classes }: { classes?: any }) {
    let stockFiltersStore = StockFiltersContainer.useContainer()

    let changeRestriction = (restriction: number, startDate: string, endDate: string) => {
        stockFiltersStore.filters.setResellersRestrictionEnd(endDate)
        stockFiltersStore.filters.setResellersRestrictionStart(startDate)
        stockFiltersStore.filters.setResellersRestrictionPercentage(restriction)
    }

    let formatRestrictionResellerText = () => {
        if (!stockFiltersStore.filters.resellersRestrictionPercentage
            || !stockFiltersStore.filters.resellersRestrictionStart
            || !stockFiltersStore.filters.resellersRestrictionEnd)
            return t('stock.stockTable.noActiveRestriction')

        let percentage = stockFiltersStore.filters.resellersRestrictionPercentage
        let startDate = moment(stockFiltersStore.filters.resellersRestrictionStart).format('MM/DD')
        let endDate = moment(stockFiltersStore.filters.resellersRestrictionEnd).format('MM/DD')
        return percentage + `% (${startDate} - ${endDate})`
    }

    return (
        <>
            <div className={classes.container} onClick={() => dialogRef?.current?.open()}>
                <div className={classes.title}>{t('stock.stockTable.restrictionTitle')}</div>
                <Button className={classes.confirmButton + ' ' + classes.restrictionButton}
                    label={<span className={classes.restrictionButtonText}>
                        <Typography variant='caption' className={classes.secondaryText}>
                            {formatRestrictionResellerText()}
                        </Typography>
                    </span>}
                    color='primary' />
            </div>
            <SelectRestrictionDialog classes={classes} onConfirm={changeRestriction}
                restriction={stockFiltersStore.filters.resellersRestrictionPercentage}
                startDate={stockFiltersStore.filters.resellersRestrictionStart}
                endDate={stockFiltersStore.filters.resellersRestrictionEnd} />
        </>
    )
}

type SelectRestrictionDialogProps = {
    restriction: number | null
    startDate: string | null
    endDate: string | null
    onConfirm: (restriction: number, startDate: string, endDate: string) => void
} & MuiProps

function SelectRestrictionDialog(props: SelectRestrictionDialogProps) {
    let state = useSelectRestrictionDialog(props)

    return (
        <Dialog open={state.isOpen} onClose={close} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
            <DialogTitle>{t('stock.stockTable.restrictionTitle')}</DialogTitle>
            <DialogContent className={props.classes.dialogContent}>
                <DateRangePicker startDate={state.startDate} startLabel={t('stock.stockTable.restrictionFrom')}
                    endDate={state.endDate} endLabel={t('stock.stockTable.restrictionTo')} autoReplace={true}
                    onPeriodChange={period => state.setDates(period.startDate, period.endDate)} disableNewStyle
                    classesOverride={{ datepicker: props.classes.resellersDate }}
                    onError={state.isStartDateOnError} />
                <NumberField
                    label={t('stock.stockTable.resselersRestrictionLabel')} allowNegative={false}
                    onChange={event => state.setRestriction(!event ? 0 : (event > 100 ? 100 : event))}
                    min={0} max={100} disableNewStyle
                    unit={'%'} text={state.restriction} />
            </DialogContent>
            <DialogActions className={props.classes.marginTop1em}>
                <Button onClick={state.close}
                    label={t('components.dialogClose')}
                    color='primary'
                    className={props.classes.cancelButton} />
                <Button onClick={state.confirm}
                    label={t('components.dialogConfirm')}
                    color='primary'
                    className={props.classes.confirmButton} />
            </DialogActions>
        </Dialog>
    )
}

function useSelectRestrictionDialog(props: SelectRestrictionDialogProps) {
    let [isOpen, setIsOpen] = useState<boolean>(false)
    let [restriction, setRestriction] = useState<number>(props.restriction || 0)
    let [startDate, setStartDate] = useState<string | null>(props.startDate)
    let [endDate, setEndDate] = useState<string | null>(props.endDate)
    let [isStartDateOnError, setIsStartDateOnError] = useState<boolean>(false)
    let [isEndDateOnError, setIsEndDateOnError] = useState<boolean>(false)

    useEffect(() => { initValues() }, [])

    dialogRef = useRef<{ open: () => void }>(null)

    useImperativeHandle(dialogRef, () => ({
        open: () => { setIsOpen(true) }
    }))

    function initValues() {
        setStartDate(props.startDate)
        setEndDate(props.endDate)
        setRestriction(props.restriction ?? 0)
    }

    function close() {
        setIsOpen(false)
        setIsStartDateOnError(false)
        setIsEndDateOnError(false)
    }

    function confirm() {
        if (!!startDate && !!endDate) {
            props.onConfirm(restriction, startDate, endDate)
            close()
        }
        else {
            if (!startDate)
                setIsStartDateOnError(true)
            if (!endDate)
                setIsEndDateOnError(true)
        }
    }

    function setDates(startDate: string | null, endDate: string | null) {
        setStartDate(startDate)
        setEndDate(endDate)
        setIsEndDateOnError(false)
    }

    return {
        isOpen, startDate, endDate, setDates,
        isStartDateOnError, setIsStartDateOnError,
        isEndDateOnError, setIsEndDateOnError, restriction, setRestriction,
        close, confirm
    }
}

let dialogRef: RefObject<{ open: () => void }> | null = null

let styles = _ =>
    createStyles({
        container: {
            padding: '0 1em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            cursor: 'pointer'
        },
        title: {
            color: 'rgba(0, 0, 0, 0.54)',
            fontSize: '0.8em',
            fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
            marginTop: '0.5em'
        },
        confirmButton: { ...defaultStyles.dialogPrimaryButton },
        cancelButton: { ...defaultStyles.dialogCloseButton },
        dialogContent: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '40em',
            '& > *': {
                width: '10em'
            }
        },
        resellersDate: {
            width: '12em'
        },
        restrictionButton: {
            margin: '0',
            marginBottom: '0.7em',
            padding: '0.4em',
        },
        marginTop1em: {
            marginTop: '1em'
        }
    })

export let ResellerRestrictionFilter = withStyles(styles, muiOptions)(_ResellerRestrictionFilter)