import { useEffect, useState } from "react"
import { Item } from "./components/select/types"

export function useFilter<T>(func: (x: T[]) => (Item[] | string[])) {
    let [items, setItems] = useState<T[]>([])
    let [choices, setChoices] = useState<Item[] | string[]>([])
    let [selectedChoices, setSelectedChoices] = useState<string[]>([])

    let values = (): string[] => {
        if (selectedChoices.length !== 0)
            return selectedChoices
        else
            return choices.map(x => typeof x === 'string' ? x : x.value)
    }

    let setItemsAndChoices = (items: T[]) => {
        setItems(items)
        setChoices(func(items))
    }

    let firstChoice = (): string | null => {
        if (items.length < 1)
            return null
        return typeof choices[0] === 'string' ? choices[0] : choices[0].value
    }

    return { items, setItems: setItemsAndChoices, choices, selectedChoices, setSelectedChoices, values, firstChoice }
}

export type Filter = ReturnType<typeof useFilter>

type Values<T> = {
    [P in keyof T]: string[]
}

export function mapValues<T extends { [key: string]: Filter }>(obj: T): Values<T> {
    let result = {} as Values<T>

    for (let prop in obj)
        result[prop] = obj[prop].values() as any

    return result
}