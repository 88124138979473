import React from "react";
import { MuiProps } from "../../../infrastructure/materialUiThemeProvider";
import { CustomButton } from "../../../app/common/components/button";
import { hasFeature } from "../../../infrastructure/feature";
import {
    GoodReceipt, GoodsTransfer, PurchaseOrder, SaleDeliveryNote, SaleGoodsIssue, SalesOrder, SapFlowStep, SapFlowStepName, SapTypes,
    TransferDeliveryNote, TransferGoodsIssue, StockTransferOrder
} from "../../../app/common/sapHelper";

type SapFiltersProps = {
    inOutSwitch: string
    onClick: (sapTypes: SapTypes[]) => void
}

export function SapFilters({ inOutSwitch, onClick, classes }: SapFiltersProps & MuiProps) {
    let [selectedSapTypes, setSelectedSapTypes] = React.useState<SapTypes[]>([])

    const dictionnary: Record<string, SapFlowStep[]> = {
        'in': [PurchaseOrder, GoodReceipt],
        'out': [SalesOrder, SaleDeliveryNote, SaleGoodsIssue],
        'all': [StockTransferOrder, TransferDeliveryNote, TransferGoodsIssue, GoodsTransfer]
    }

    let click = (sapType: SapTypes) => {
        if (selectedSapTypes.some(x => x == sapType)) {
            let newSelectedSapTypes = selectedSapTypes.filter(x => x != sapType)
            setSelectedSapTypes(newSelectedSapTypes)
            onClick(newSelectedSapTypes)
        } else {
            let newSelectedSapTypes = [...selectedSapTypes, sapType]
            setSelectedSapTypes(newSelectedSapTypes)
            onClick(newSelectedSapTypes)
        }
    }

    let step = (step: SapFlowStep) => {
        return <CustomButton
            key={step.sapType}
            className={selectedSapTypes.some(x => x == step.sapType) ? classes.sapButtonSelected : classes.sapButton}
            classes={{ label: classes.noPadding }}
            label={step.shortName ?? ''}
            onClick={(event) => { event.stopPropagation(); click(step.sapType) }}
        />
    }
    return (<div>
        {dictionnary[inOutSwitch].map(step)}
        {hasFeature('TransferSapFlowInSaleAndPurchase') && dictionnary['all'].map(step)}
    </div>)
}