import React, { useEffect } from 'react'
import { withStyles, createStyles, Paper, Typography } from '@material-ui/core'
import { muiOptions, defaultStyles, defaultColors } from '../../infrastructure/materialUiThemeProvider'
import { SupplyBalanceStore } from './supplyBalanceStore'
import { SupplyBalanceFilters } from './filters/_supplyBalanceFilters'
import { SupplyBalanceTable } from './supplyBalanceTable'
import { t } from '../../infrastructure/i18nextHelper'
import { SupplyBalance, SupplyBalancePeriod } from './supplyBalanceModels'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Switch } from '../common/customComponents'
import { UsefulStock } from '../stock/filters/_stockPercentUsefulStockFilters'
import { UserContextContainer } from '../../infrastructure/signIn/userContext'
import { StockFiltersContainer } from '../stock/filters/filtersStore'

function _SupplyBalance({ classes }) {
    let store = SupplyBalanceStore.useContainer()
    let stockFiltersStore = StockFiltersContainer.useContainer()
    let userContext = UserContextContainer.useContainer()

    useEffect(() => {
        if (!userContext.isLoggedIn) return

        store.loadFilters()
        var subscription = store.initializeSubscription()

        return () => {
            subscription.unsubscribe()
        }
    }, [])

    useEffect(() => {
        if (stockFiltersStore.filtersInitialized) {
            store.setDisplayBody(true)
            store.loadSupplyBalance()
        }
    }, [store.formatDatesToTriggerChanges(), stockFiltersStore.filters])

    let setDisabledInput = (period: SupplyBalancePeriod, isStockTarget: boolean) => {
        let supplyBalance = store.supplyBalanceFromPeriod(period)

        if (!supplyBalance) return

        let needSet = supplyBalance.supplyBalanceResult?.balances.map(b => b.need)
        let hasStockTarget = needSet!.some(n => n.stockTarget)
        let hasStockTargetDays = needSet!.some(n => n.stockTargetDays)

        if (isStockTarget)
            store.setDisabledStockTargetDays(hasStockTarget)
        else
            store.setDisabledStockTarget(hasStockTargetDays)
    }

    let setSupplyNeed = (period: SupplyBalancePeriod, index: number, balanceLine: SupplyBalance, stockTarget: number | null, isStockTarget: boolean) => {
        let supplyBalance = store.supplyBalanceFromPeriod(period)
        if (!supplyBalance) return

        let balances = supplyBalance.supplyBalanceResult?.balances
        if (!balances) return

        let newNeed = balanceLine.need

        if (isStockTarget) {
            newNeed.stockTarget = numberOrNull(stockTarget)
            newNeed.ullageEnd = numberOrNull(newNeed.maxCapacity! - (stockFiltersStore.filters.usefulStock ? (newNeed.stockTarget! + newNeed.deadStock!) : newNeed.stockTarget!))
            newNeed.stockTargetDays = newNeed.averageDemandPerDay != null && newNeed.averageDemandPerDay != 0
                ? numberOrNull((store.kUnite ? newNeed.stockTarget! * 1000 : newNeed.stockTarget!) / newNeed.averageDemandPerDay)
                : null
        }
        else {
            newNeed.stockTargetDays = numberOrNull(stockTarget)
            newNeed.stockTarget = numberOrNull(store.kUnite ? (newNeed.stockTargetDays! * newNeed.averageDemandPerDay!) / 1000 : (newNeed.stockTargetDays! * newNeed.averageDemandPerDay!))
            newNeed.ullageEnd = numberOrNull(newNeed.maxCapacity! - (stockFiltersStore.filters.usefulStock ? (newNeed.stockTarget! + newNeed.deadStock!) : newNeed.stockTarget!))
        }

        let demandTotal = store.demandTotal(balanceLine.demand)
        let resourceTotal = store.resourceTotal(balanceLine.resource)
        newNeed.need = store.kUnite
            ? numberOrNull(newNeed.stockTarget! * 1000 + demandTotal - resourceTotal)
            : numberOrNull(newNeed.stockTarget! + demandTotal - resourceTotal)

        balances[index] = { ...balanceLine, need: newNeed }

        let subTotalIndex = balances.findIndex(x => x.isSubTotal && x.siteGroup === balanceLine.siteGroup)
        let balancesFromSiteGroup = balances.filter(x => !x.isSubTotal && x.siteGroup == balanceLine.siteGroup)
        balances[subTotalIndex] = {
            ...balances[subTotalIndex], need: {
                ...balances[subTotalIndex].need,
                stockTarget: balancesFromSiteGroup.map(x => x.need.stockTarget).reduce((acc: number, curr: number | null) => acc + (curr ?? 0), 0),
                need: balancesFromSiteGroup.map(x => x.need.need).reduce((acc: number, curr: number | null) => acc + (curr ?? 0), 0)
            }
        }

        store.changeSupplyBalancesResults(supplyBalance.period, balances)
    }

    let numberOrNull = (value: number | null) => (value !== 0 && value !== null) || store.disabledStockTarget || store.disabledStockTargetDays ? value : null

    let onChangePeriod = async (period: SupplyBalancePeriod, start: string | null, end: string | null) => {
        let supplyBalance = store.supplyBalanceFromPeriod(period)
        if (!supplyBalance) return
        store.changePeriod(supplyBalance.period, start, end)
    }

    let onToggleUsefulStock = () => { stockFiltersStore.filters.setUsefulStock(!stockFiltersStore.filters.usefulStock) }

    return (
        <div className={classes.pageContainer}>
            <div className={classes.firstRow}>
                <div className={classes.titleTourContainer}>
                    <Typography className={classes.pageTitle} variant='h5'
                        display='block' gutterBottom>{t('supplyBalance.name')}</Typography>
                </div>

                <div className={classes.bar}>
                    <Paper>
                        <UsefulStock isChecked={stockFiltersStore.filters.usefulStock} toggleHandler={onToggleUsefulStock} />
                        <Switch form isChecked={store.kUnite}
                            changeCallback={() => store.setKUnite(!store.kUnite)}
                            offText="M3"
                            onText="k.M3" />
                        <FormControlLabel
                            control={<Checkbox onChange={() => store.setDemandDetails(!store.demandDetails)} checked={store.demandDetails} />}
                            label={t('supplyBalance.uiSettings.demandDetails')}
                        />
                        <FormControlLabel
                            control={<Checkbox onChange={() => store.setRessourcesDetails(!store.ressourcesDetails)} checked={store.ressourcesDetails} />}
                            label={t('supplyBalance.uiSettings.ressourcesDetails')}
                        />
                    </Paper>
                    <Paper>
                        <SupplyBalanceFilters />
                    </Paper>
                </div>
            </div>
            <div className={classes.secondRow}>
                {
                    store.displayBody && store.supplyBalanceTables.length >= 0 && store.supplyBalanceTables.map((x, i) =>
                        <SupplyBalanceTable key={`supplyBalanceTable-${i}`} supplyBalanceTable={x} supplyBalanceTableIndex={i}
                            disabledStockTarget={store.disabledStockTarget} onAddPeriod={i === 0 ? store.addPeriod : undefined} onChangePeriod={onChangePeriod}
                            disabledStockTargetDays={store.disabledStockTargetDays} setDisabledInput={setDisabledInput}
                            setSupplyNeed={setSupplyNeed} />)
                }
            </div>
        </div>
    )
}
let styles = () =>
    createStyles({
        pageContainer: {
            display: 'grid',
            height: '100%',
            width: '100%',
            overflowY: 'hidden',
            overflowX: 'hidden',
            'grid-template-columns': 'auto',
            'grid-template-rows': '4em minmax(0, 1fr)',
            'grid-template-areas': '"filters" "supplyBalanceTable "',
            'grid-gap': '0.7em',
            'box-sizing': 'border-box',
            '&> div': {
                height: '100%',
                width: '100%',
                boxSizing: 'border-box'
            }
        },
        bar: {
            height: '4em',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            '& > *': {
                height: '100%',
                padding: '0em 0.3em',
                display: 'flex',
                alignItems: 'center',
                marginRight: '1em',
                '&:last-child': {
                    marginRight: '0em'
                }
            },
        },
        titleTourContainer: {
            ...defaultStyles.flexRow,
        },
        pageTitle: {
            color: defaultColors.grey.dark.color,
            margin: '0'
        },
        firstRow: {
            'grid-area': 'filters',
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            alignItems: 'start',
        },
        secondRow: {
            'grid-area': 'supplyBalanceTable',
            overflow: 'auto',
            height: '100%'
        }
    })

export default withStyles(styles, muiOptions)(_SupplyBalance)