import { MovementForm, SapFlow, ReleaseIndicator, MovementType, MovementStatus } from '../../../../stockModels'
import { StockMovementStore } from '../../../stockMovementStore'
import { t } from '../../../../../../infrastructure/i18nextHelper'
import { hasFeature } from '../../../../../../infrastructure/feature'
import {
    deliveryAction, DirectTransfer, Gains, GoodReceipt, goodsIssueAction, goodsMovementAction, GoodsTransfer, Losses,
    PurchaseOrder, PurchaseRelease, Rebranding, SaleDeliveryNote, SaleGoodsIssue, SalesOrder, SapTypes, TransferDeliveryNote,
    TransferGoodsIssue, StockTransferOrder
} from '../../../../../../app/common/sapHelper'

type FormRequirements = {
    [P in SapTypes]: { [P in keyof MovementForm]?: 'filled' | 'empty' }
}

export const formRequirements = (isMainSapFlow: boolean, mainSapFlowMvtType: MovementType | null): FormRequirements => {
    return {

        sale: {
            meanOfTransportation: 'filled',
            volume: 'filled',
            stockOutputDate: 'filled',
            reference: 'filled',
        },

        sto: {
            company: 'filled',
            volume: 'filled',
            meanOfTransportation: 'filled',
            ...(isMainSapFlow ?
                {
                    destinationProductId: 'filled',
                    stockOutputDate: 'filled',
                    stockInputDate: 'filled',
                    originSite: 'filled',
                    destinationSite: 'filled'
                }
                : mainSapFlowMvtType == MovementType.Purchase
                    ? { destinationProductId: 'filled', stockInputDate: 'filled', destinationSite: 'filled' }
                    : { originProductId: 'filled', stockOutputDate: 'filled', originSite: 'filled' }
            ),
        },
        stoDn: {},
        stoGoodsIssue: {},

        migo: isMainSapFlow ?
            {
                stockInputDate: 'filled',
                stockOutputDate: 'filled',
            }
            : mainSapFlowMvtType == MovementType.Purchase
                ? { stockInputDate: 'filled' }
                : { stockOutputDate: 'filled' },

        directTransfer: {
            reference: 'filled',
            destinationDutyStatus: 'filled',
            destinationProductId: 'filled',
            volume: 'filled',
            company: 'filled',
            originSite: 'filled',
            destinationSite: 'filled',
        },
        rebranding309: {
            stockInputDate: 'filled',
            reference: 'filled',
            destinationDutyStatus: 'filled',
            volume: 'filled',
            originProductId: 'filled',
            destinationProductId: 'filled',
            destinationSite: 'filled',
            company: 'filled',
        },
        purchase501: {
            reference: 'filled',
            destinationDutyStatus: 'filled',
            destinationSite: 'filled',
            destinationProductId: 'filled',
            volume: 'filled',
            counterpartyId: 'filled',
            company: 'filled'
        },
        poRelease: {
        },
        gains: {
            company: 'filled',
            reference: 'filled',
            destinationSite: 'filled',
            destinationDutyStatus: 'filled',
            destinationProductId: 'filled',
            volume: 'filled',
            stockInputDate: 'filled',
        },
        losses: {
            company: 'filled',
            reference: 'filled',
            destinationSite: 'filled',
            destinationDutyStatus: 'filled',
            destinationProductId: 'filled',
            volume: 'filled',
            stockOutputDate: 'filled',
        },
        poMigo: {
            stockInputDate: 'filled',
            volume: 'filled',
            reference: 'filled',
        },
        soDn: {},
        soGoodsIssue: {}
    }
}

export const sapFlowRequirements: { [P in SapTypes]: { [P in keyof SapFlow]?: 'filled' | 'empty' } } = {
    sale: {
        originPlant: 'filled',
        originStorageLocation: 'filled',
        referenceOrder: 'empty',
    },
    sto: {
        originPlant: 'filled',
        originStorageLocation: 'filled',
        destinationPlant: 'filled',
        destinationStorageLocation: 'filled',
        referenceOrder: 'empty',
        deliveryNote: 'empty',
        goodsIssue: 'empty',
        materialDocument: 'empty'
    },
    stoDn: {
        referenceOrder: 'filled',
        deliveryNote: 'empty',
        goodsIssue: 'empty',
        materialDocument: 'empty'
    },
    stoGoodsIssue: {
        referenceOrder: 'filled',
        deliveryNote: 'filled',
        goodsIssue: 'empty',
        materialDocument: 'empty'
    },
    migo: {
        referenceOrder: 'filled',
        deliveryNote: 'filled',
        materialDocument: 'empty',
        goodsIssue: 'filled'
    },
    directTransfer: {
        originPlant: 'filled',
        destinationPlant: 'filled',
        originStorageLocation: 'filled',
        destinationStorageLocation: 'filled',
        materialDocument: 'empty'
    },
    rebranding309: {
        originPlant: 'filled',
        originStorageLocation: 'filled',
        destinationStorageLocation: 'filled',
        materialDocument: 'empty'
    },
    purchase501: {
        destinationPlant: 'filled',
        destinationStorageLocation: 'filled',
        referenceOrder: 'empty',
    },
    poRelease: {
        referenceOrder: 'filled',
        deliveryNote: 'empty'
    },
    gains: {
        destinationPlant: 'filled',
        destinationStorageLocation: 'filled',
        materialDocument: 'empty'
    },
    losses: {
        destinationPlant: 'filled',
        destinationStorageLocation: 'filled',
        materialDocument: 'empty'
    },
    poMigo: {
        referenceOrder: 'filled',
        destinationPlant: 'filled',
        destinationStorageLocation: 'filled',
        materialDocument: 'empty'
    },
    soDn: {
        referenceOrder: 'filled',
        deliveryNote: 'empty',
        goodsIssue: 'empty',
        materialDocument: 'empty'
    },
    soGoodsIssue: {
        referenceOrder: 'filled',
        deliveryNote: 'filled',
        goodsIssue: 'empty',
        materialDocument: 'empty'
    }
}

export function isValidated(type: SapTypes, sapFlow: SapFlow) {
    return (type == PurchaseRelease.sapType && isPurchaseOrderValidated(sapFlow))
}

export function isEnabled(type: SapTypes, form: MovementForm, sapFlow: SapFlow, hasDeal: boolean) {
    let mvtRequirements = formRequirements(form.movementType == sapFlow.movementType, form.mainSapFlow.movementType)[type]
    let sapRequirements = sapFlowRequirements[type]

    let restrictedForActualStatus = hasFeature('RestrictDeliveryAndNextButtonForActualStatus')
        && form.movementStatus != MovementStatus.Actual
        && [...deliveryAction, ...goodsIssueAction, ...goodsMovementAction].indexOf(type) != -1
    let isMovementStatusValidated = form.movementStatus != MovementStatus.Forecast && !restrictedForActualStatus

    if (type == PurchaseRelease.sapType && isPurchaseOrderValidated(sapFlow))
        return false

    if (type == GoodReceipt.sapType && hasFeature('SapPoRelease') && !isPurchaseOrderValidated(sapFlow))
        return false

    if (isDealRequired(type) && !hasDeal)
        return false

    return (
        isMovementStatusValidated
        && Object.keys(mvtRequirements).every(prop =>
            mvtRequirements[prop] === 'filled' && form[prop] != null && form[prop] !== '' ||
            mvtRequirements[prop] === 'empty' && (form[prop] == null || form[prop] === ''))
        && Object.keys(sapRequirements).every(prop =>
            sapRequirements[prop] === 'filled' && sapFlow[prop] != null && sapFlow[prop] !== '' ||
            sapRequirements[prop] === 'empty' && (sapFlow[prop] == null || sapFlow[prop] === ''))
    )
}

export function isDealRequired(type: SapTypes) {
    switch (type) {
        case SalesOrder.sapType:
        case PurchaseOrder.sapType:
        case PurchaseRelease.sapType:
        case GoodReceipt.sapType:
        case SaleDeliveryNote.sapType:
        case SaleGoodsIssue.sapType: return true
        default: return false
    }
}

export function isDisplayable(type: SapTypes, featureContainer) {
    switch (type) {
        case SalesOrder.sapType: return featureContainer.hasFeature('SapSo')
        case PurchaseOrder.sapType: return featureContainer.hasFeature('SapPoNbCreate')
        case PurchaseRelease.sapType: return featureContainer.hasFeature('SapPoRelease')
        case Rebranding.sapType: return featureContainer.hasFeature('SapGm309')
        case Gains.sapType: return featureContainer.hasFeature('SapGmGain')
        case Losses.sapType: return featureContainer.hasFeature('SapGmLosses')
        case StockTransferOrder.sapType: return featureContainer.hasFeature('SapPoUb')
        case TransferDeliveryNote.sapType: return featureContainer.hasFeature('SapDnSto')
        case TransferGoodsIssue.sapType: return featureContainer.hasFeature('SapGiSto') && featureContainer.hasFeature('GoodsIssueOutsideDeliveryNote')
        case GoodsTransfer.sapType: return featureContainer.hasFeature('SapGmMigo')
        case DirectTransfer.sapType: return featureContainer.hasFeature('SapGmDirectTransfer')
        case GoodReceipt.sapType: return featureContainer.hasFeature('SapPoGmMigo')
        case SaleDeliveryNote.sapType: return featureContainer.hasFeature('SapDnSo')
        case SaleGoodsIssue.sapType: return featureContainer.hasFeature('SapGiSo') && featureContainer.hasFeature('GoodsIssueOutsideDeliveryNote')
    }
}

export function highlightRequiredFields(type: SapTypes, isMainSapFlow: boolean, store: StockMovementStore) {
    let mvtRequirements = formRequirements(isMainSapFlow, store.getSapFlow(true).movementType)[type]
    let sapRequirements = sapFlowRequirements[type]

    let mvtRequirementsKeys = Object.keys(mvtRequirements)
    let sapRequirementsKeys = Object.keys(sapRequirements)

    store.formErrors.clear()

    let mvtFieldsToShowAsInfo = mvtRequirementsKeys.filter(key => mvtRequirements[key] === 'filled')
    store.formErrors.movementFieldStatus.setStatus(mvtFieldsToShowAsInfo, 'info')

    let mvtFieldsToShowAsWarning = mvtRequirementsKeys.filter(key => mvtRequirements[key] === 'empty')
    store.formErrors.movementFieldStatus.setStatus(mvtFieldsToShowAsWarning, 'warning')

    let sapFieldsToShowAsInfo = sapRequirementsKeys.filter(key => sapRequirements[key] === 'filled')
    store.getSapFlowFieldStatus(isMainSapFlow).setStatus(sapFieldsToShowAsInfo, 'info')

    let sapFieldsToShowAsWarning = sapRequirementsKeys.filter(key => sapRequirements[key] === 'empty')
    store.getSapFlowFieldStatus(isMainSapFlow).setStatus(sapFieldsToShowAsWarning, 'warning')
}

function getTrad(key: string) {
    return t(`stock.label.movement.sapAction.${key}`)
}

export function getSapLabel(type: SapTypes, sapFlow: SapFlow) {
    if (type == PurchaseRelease.sapType && isPurchaseOrderValidated(sapFlow))
        return getTrad('validatedPoButton')
    return getTrad(type + 'Button')
}


export function getSapTooltip(type: SapTypes, sapFlow: SapFlow) {
    if (type == PurchaseRelease.sapType && isPurchaseOrderValidated(sapFlow))
        return getTrad('validatedPoButtonTooltip')
    return getTrad(type + 'ButtonTooltip')
}

export function isPurchaseOrderValidated(sapFlow: SapFlow): boolean {
    return referenceOrderExist(sapFlow.referenceOrder) && !needValidation(sapFlow.releaseIndicator)

    function needValidation(releaseIndicator: ReleaseIndicator) {
        return releaseIndicator == "ToBeValidated" || releaseIndicator == null
    }

    function referenceOrderExist(referenceOrder: string | null): boolean { return referenceOrder != null && referenceOrder !== '' }
}