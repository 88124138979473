import React from 'react'
import moment from 'moment'
import { withStyles } from '@material-ui/styles'
import { createStyles } from '@material-ui/core'
import { defaultStyles, muiOptions } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import { Guid } from '../../../infrastructure/guid'
import { Select, DatePicker, TextField, Switch, DateRangePicker } from '../../common/customComponents'
import { CertificateOfConformity, Counterparty } from '../vesselModels'
import { VesselEditContainer } from './vesselEditStore'
import { hasFeature } from '../../../infrastructure/feature'
import { CommentContent } from '../../common/comment/commentContent'
import { CommentButton } from '../../common/comment/commentButton'
import { Item } from '../../common/components/select/types'

function _FirstLine({ classes }) {
    let vessel = VesselEditContainer.useContainer()

    let changeCounterparty = (value: Guid | null) => {
        if (!vessel.state) return
        let newCounterparty: Counterparty = vessel.counterpartys.filter(x => x.id === value)[0]
        vessel.setState({ ...vessel.state, counterpartySupplierId: newCounterparty?.id })
    }

    let hasDealAssigned = vessel.state.products?.some(p => p.purchaseMovements?.some(pm => pm.sites?.some(s => s.dealId)))

    if (!vessel.state) return <div />
    let state = vessel.state

    return (
        <div className={classes.row}>
            <TextField label={t('vessels.label.name')}
                onChange={event => vessel.setState({ ...state, name: event.target.value })}
                text={state.name} />
            <Select label={t('vessels.label.jetty')}
                value={state.jettyCode ?? ''}
                status={vessel.formErrors.vesselFieldStatus.getStatus('jettyCode')}
                choices={vessel.jettys.map(x => x.code)}
                onChange={val => vessel.changeJetty(val)} />
            <Select label={t('vessels.label.counterparty')}
                value={state.counterpartySupplierId}
                status={vessel.formErrors.vesselFieldStatus.getStatus('counterpartySupplierId')}
                choices={vessel.counterpartys.map(x => ({ value: x.id, text: x.name }))}
                onChange={val => changeCounterparty(val)}
                isClearable={true}
                clearableLabel={t('vessels.label.counterparty')} />
            <Select label={t('vessels.label.assignee')}
                value={state.assigneeUserName}
                isClearable clearableLabel=' '
                choices={vessel.vesselUsers.map(x => ({ value: x.login, text: x.fullName }))}
                onChange={val => vessel.setState({ ...state, assigneeUserName: val })} />
            <TextField label={t('vessels.label.imo')}
                onChange={event => vessel.setState({ ...state, internationalMaritimeOrganizationNumber: event.target.value })}
                text={state.internationalMaritimeOrganizationNumber} />
        </div>
    )
}

function _SecondLine({ classes }) {
    let vessel = VesselEditContainer.useContainer()

    if (!vessel.state) return <div />
    let state = vessel.state

    return (
        <div className={classes.row}>

            <DateRangePicker startDate={state.originalLaycanStartDate} startLabel={t('vessels.label.originalLaycanStart')}
                endDate={state.originalLaycanEndDate} endLabel={t('vessels.label.originalLaycanEnd')}
                startStatus={vessel.formErrors.vesselFieldStatus.getStatus('originalLaycanStartDate')}
                endStatus={vessel.formErrors.vesselFieldStatus.getStatus('originalLaycanEndDate')} autoReplace={true}
                onPeriodChange={period => vessel.setState(
                    { ...state, originalLaycanStartDate: period.startDate, originalLaycanEndDate: period.endDate })} />
            <DateRangePicker startDate={state.revisedLaycanStartDate} startLabel={t('vessels.label.revisedLaycanStart')}
                endDate={state.revisedLaycanEndDate} endLabel={t('vessels.label.revisedLaycanEnd')} autoReplace={true}
                startAuto={!state.revisedLaycanStartDateOverwritten} endAuto={!state.revisedLaycanEndDateOverwritten}
                onPeriodChange={period => {
                    vessel.setState({
                        ...state,
                        revisedLaycanStartDate: period.startDate,
                        revisedLaycanStartDateOverwritten: !!period.startDate && moment(period.startDate).isValid(),
                        revisedLaycanEndDate: period.endDate,
                        revisedLaycanEndDateOverwritten: !!period.endDate && moment(period.endDate).isValid()
                    })
                }} />
        </div>
    )
}

function _ThirdLine({ classes }) {
    let vessel = VesselEditContainer.useContainer()

    if (!vessel.state) return <div />
    let state = vessel.state

    return (
        <div className={classes.row}>
            {vessel.isAuthorized('norDate') &&
                <DatePicker date={state.norDate}
                    auto={!state.norDateOverwritten}
                    label={t('vessels.label.norDate')}
                    setDate={newDate => vessel.setState({ ...state, norDate: newDate, norDateOverwritten: !!newDate && moment(newDate).isValid() })} />}
            {vessel.isAuthorized('etaDate') &&
                <DatePicker date={state.etaDate}
                    auto={!state.etaDateOverwritten}
                    label={t('vessels.label.etaDate')}
                    setDate={newDate => vessel.setState({ ...state, etaDate: newDate, etaDateOverwritten: !!newDate && moment(newDate).isValid() })} />}
            <DatePicker date={state.berthingDate}
                auto={!state.berthingDateOverwritten}
                label={t('vessels.label.berthingDate')}
                setDate={newDate => vessel.setState({ ...state, berthingDate: newDate, berthingDateOverwritten: !!newDate && moment(newDate).isValid() })} />
            <DatePicker date={state.completionOfDischargeDate}
                auto={!state.completionOfDischargeDateOverwritten}
                label={t('vessels.label.cod')}
                setDate={newDate => vessel.setState({ ...state, completionOfDischargeDate: newDate, completionOfDischargeDateOverwritten: !!newDate && moment(newDate).isValid() })} />
            <DatePicker
                date={state.availabilityDate}
                auto={!state.availabilityDateOverwritten}
                label={t('vessels.label.availabilityDate')}
                setDate={newDate => vessel.setState({ ...state, availabilityDate: newDate, availabilityDateOverwritten: !!newDate && moment(newDate).isValid() })} />
            {vessel.isAuthorized('bankGuaranteeDate') &&
                <DatePicker
                    date={state.bankGuaranteeDate}
                    auto={!state.bankGuaranteeDateOverwritten}
                    label={t('vessels.label.bankGuaranteeDate')}
                    setDate={newDate => vessel.setState({ ...state, bankGuaranteeDate: newDate, bankGuaranteeDateOverwritten: !!newDate && moment(newDate).isValid() })} />}
        </div>
    )
}

function _FourthLine({ classes }) {
    let vessel = VesselEditContainer.useContainer()

    let certificateOfConformitys: Item[] =
        Object.keys(CertificateOfConformity).filter(x => isNaN(+x)).map((x, i) => { return { value: i.toString(), text: t('vessels.types.certificateOfConformity.' + x.valueOf()) } })

    let updatePaymentTermsDate = (newDate: string | null) => {
        if (!newDate)
            vessel.setState({ ...state, paymentTermsDate: null, paymentTermsDateOverwritten: false })
        else
            vessel.setState({ ...state, paymentTermsDate: newDate, paymentTermsDateOverwritten: true })
    }

    if (!vessel.state) return <div />
    let state = vessel.state

    return (
        <div className={classes.row}>
            {vessel.isAuthorized('certificateOfConformity') &&
                <Select label={t('vessels.label.certificate')}
                    value={state.certificateOfConformity}
                    choices={certificateOfConformitys}
                    onChange={val => vessel.setState({ ...state, certificateOfConformity: +val })} />}
            {vessel.isAuthorized('vesselClearance') &&
                <Switch form label={t('vessels.label.clearance')}
                    isChecked={state.hasClearance}
                    changeCallback={() => vessel.setState({ ...state, hasClearance: !state.hasClearance })}
                    offText={'No'} onText={'Yes'} />}
            <Select label={t('vessels.label.paymentTerms')}
                value={state.paymentTerms !== null ? state.paymentTerms : ''}
                choices={vessel.paymentTerms.map(x => ({ value: x.value.toString(), text: t('referential.paymentTerms.' + x.code) }))}
                onChange={val => { if (val) vessel.setState({ ...state, paymentTerms: +val }) }} />
            <DatePicker date={state.paymentTermsDate} label={t('vessels.label.paymentTermsDate')} auto={!state.paymentTermsDateOverwritten}
                setDate={newDate => updatePaymentTermsDate(newDate)} />
            {vessel.isAuthorized('vettingNumber') &&
                <TextField label={t('vessels.label.vettingNumber')}
                    onChange={event => vessel.setState({ ...state, vettingNumber: event.target.value })}
                    text={state.vettingNumber} />}
        </div>
    )
}

function _FifthLine({ classes }) {
    let vessel = VesselEditContainer.useContainer()
    if (!vessel.state) return <div />

    let associationKey = `vessel-${vessel.state.id}`

    return (
        <div className={classes.row}>
            <CommentContent associationKey={associationKey} />
            <CommentButton withPadding associationKey={associationKey} />
        </div>
    )
}

let styles = _ =>
    createStyles({
        row: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: '1em',
            marginBottom: '1em'
        }
    })

export let FirstLine = withStyles(styles, muiOptions)(_FirstLine)
export let SecondLine = withStyles(styles, muiOptions)(_SecondLine)
export let ThirdLine = withStyles(styles, muiOptions)(_ThirdLine)
export let FourthLine = withStyles(styles, muiOptions)(_FourthLine)
export let FifthLine = withStyles(styles, muiOptions)(_FifthLine)
