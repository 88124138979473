import React, { useEffect } from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import { muiOptions, MuiProps, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import { MultipleSelect, GroupedMultiSelect, DateRangePicker } from '../../common/customComponents'
import { StockBoardContainer } from '../stockBoardStore'
import { StockFiltersContainer } from './filtersStore'

function _StockBoardFilters({ classes }: MuiProps) {
    let store = StockBoardContainer.useContainer()
    let stockFiltersStore = StockFiltersContainer.useContainer()

    useEffect(() => { store.loadFiltersElements() }, [])

    return (
        <div className={'stockFilters'}>
            <DateRangePicker startDate={stockFiltersStore.filters.start || null} startLabel={t('stock.filters.startDate')}
                endDate={stockFiltersStore.filters.end || null} endLabel={t('stock.filters.endDate')} disableNewStyle
                onPeriodChange={period => store.changePeriod(period)}
                onError={store.filterDatesOnError} classesOverride={{ datepicker: classes.filterFieldDate }} />
            <GroupedMultiSelect
                label={t('stock.filters.sites')} classesOverride={{ form: classes.filterField }} disableNewStyle
                filter={stockFiltersStore.filters.sitesFilter} />
            {stockFiltersStore.filters.productIdsFilter.items.every(x => !x.productGroup)
                ? <MultipleSelect label={t('stock.filters.product')} classesOverride={{ form: classes.filterField }}
                    filter={stockFiltersStore.filters.productIdsFilter} disableNewStyle hideOnSingleChoice />
                : <GroupedMultiSelect label={t('stock.filters.product')} classesOverride={{ form: classes.filterField }}
                    filter={stockFiltersStore.filters.productIdsFilter} hideOnSingleChoice disableNewStyle />}
            <MultipleSelect label={t('stock.filters.dutyStatus')} classesOverride={{ form: classes.filterField }}
                filter={stockFiltersStore.filters.dutyStatusesFilter} disableNewStyle hideOnSingleChoice />
            <MultipleSelect label={t('stock.filters.company')} classesOverride={{ form: classes.filterField }}
                values={stockFiltersStore.filters.companiesFilter.selectedChoices}
                filter={stockFiltersStore.filters.companiesFilter} disableNewStyle />
        </div>
    )
}

let styles = theme =>
    createStyles({
        filterField: {
            width: '8.3em',
            margin: '0em 0.5em'
        },
        filterFieldDate: {
            width: '10.3em',
            margin: '0em 0.5em'
        },
        searchFilter: {
            ...defaultStyles.secondaryButton
        }
    })

export let StockBoardFilters = withStyles(styles, muiOptions)(_StockBoardFilters)