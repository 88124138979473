
import { Item } from './types'

export function formatChoices(choices: Item[] | string[]) {
    let array = choices

    if (array && Array.isArray(array) && array.length > 0 && (array as Array<any>).every(x => typeof x !== 'object'))
        array = (array as string[]).map(x => { return { value: x, text: x } })
    else
        array = (array as Item[])?.map(x => ({ value: x.value?.toString(), text: x.text?.toString() }))

    return (array ?? []) as Item[]
}