import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { createStyles, withStyles, Paper, Typography, Tabs, Tab } from '@material-ui/core'
import { muiOptions, defaultStyles, defaultColors } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import { Claims } from '../../../infrastructure/signIn/models'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { hasFeature } from '../../../infrastructure/feature'
import { Select, MonthPicker, AttachFileButton, Button, Switch, DateRangePicker } from '../../common/customComponents'
import { Popup, ButtonPopupParams } from '../../common/components/popup'
import { ForecastContainer } from './forecastEditStore'
import CustomerSegmentTable from './_forecastEditTable'
import { MktSaleForecastValue, SnapshotType } from '../models'
import { SnapshotDialogs } from '../_snapshotDialogs'
import { useSnapshotLabel } from '../useSnapshotLabel'

function ForecastEdit({ classes, closePopup, isOpen }) {
    let [tabIndex, setTabIndex] = useState(0)
    let store = ForecastContainer.useContainer()

    let { getSnapshotLabel } = useSnapshotLabel()

    let onClosePopup = () => {
        store.setDefaultValues()
        closePopup()
    }

    let onSave = async () => {
        await store.save()
        store.setDefaultValues()
        closePopup()
    }

    let setMarketSalesForecastValue = (index: number, newValue: MktSaleForecastValue) => {
        let array = store.mktSaleForecast.values
        if (array !== null) {
            array[index] = newValue
            store.setMktSaleForecast({ ...store.mktSaleForecast, values: array })
        }
    }

    let shouldHideSaveButtonForPast = () => {
        if (store.isCompanyMonthFilled()) {
            let filterDate = moment(store.mktSaleForecast.date as string)
            let currentDate = moment()

            let isPastMonth = filterDate.month() < currentDate.month()
            let isSameMonth = filterDate.month() === currentDate.month()
            let isSameYear = filterDate.year() === currentDate.year()
            let isPastYear = filterDate.year() < currentDate.year()
            let isFutureYear = filterDate.year() > currentDate.year()

            let isPast = isPastYear || (isPastMonth && (isPastYear || isSameYear) && !isFutureYear)
                || (!isSameMonth && !isSameYear && !isFutureYear)
            return !hasFeature('AllowPastMktSalesDemandModification') && isPast
        }
        return true
    }

    useEffect(() => {
        if (isOpen)
            store.loadForecastDatas()
    }, [isOpen])

    useEffect(() => {
        store.setCustomerSegmentGroupedByChannel(store.channelCustomerSegments.indexMultipleValueByProp('channel'))
    }, [store.channelCustomerSegments, store.companies])

    useEffect(() => {
        let reload = async () => {
            if (store.isCompanyMonthFilled()) {
                let channelCustomerSegmentFilteredByCompany = store.channelCustomerSegments
                    .filter(x => x.company === store.mktSaleForecast.company)
                store.setCustomerSegmentGroupedByChannel(channelCustomerSegmentFilteredByCompany.indexMultipleValueByProp('channel'))
                await store.loadMktSales()
                await store.loadProductByCustomerSegment()
            }
        }
        reload()
    }, [store.mktSaleForecast.company, store.startDate, store.endDate, store.isSecondFortnight])

    let mode = hasFeature('MktSalesForecastByPeriod') ? 'period' : hasFeature('MktSalesForecastByFortnight') ? 'fortnight' : 'month'
    let isManager = hasClaim(Claims.SalesManager)

    let buttons: ButtonPopupParams[] = [{
        buttonEffect: () => onSave(),
        buttonText: t('components.updateButton'),
        hideButton: !isManager || shouldHideSaveButtonForPast() || hasFeature('MktSalesForecastShipTo')
    }]

    let snapshotTypes: SnapshotType[] = hasFeature('MktSalesLongTermPlan') ? ['Initial', 'LongTermPlan'] : ['Initial']

    return (
        <Popup title={t('mktSales.forecast.popupLabel')}
            close={() => onClosePopup()}
            isOpen={isOpen}
            buttons={buttons}>
            <div>
                <Paper className={classes.paperFilter}>
                    <div>
                        <Select label={t('mktSales.forecast.company')}
                            value={store.mktSaleForecast?.company}
                            choices={store.companies.map(x => ({ value: x.code, text: x.name }))} disableNewStyle
                            onChange={val => store.setMktSaleForecast({ ...store.mktSaleForecast, company: val })} />

                        {mode == 'period' && <>
                            <DateRangePicker startDate={store.startDate} startLabel={t('mktSales.forecast.startDate')}
                                endDate={store.endDate} endLabel={t('mktSales.forecast.endDate')} disableNewStyle
                                autoReplace={true} onPeriodChange={period => {
                                    store.setDates(period.startDate, period.endDate)
                                }} />
                            {store.numberOfCalendarDays && <div className={classes.fortnightPicker}>
                                <Typography >
                                    {t("mktSales.forecast.calendarDays")} {store.numberOfCalendarDays}
                                </Typography>
                            </div>}
                        </>}

                        {(mode == 'month' || mode == 'fortnight') &&
                            <MonthPicker date={store.startDate} label={t('mktSales.forecast.month')}
                                setDate={newDate => setTimeout(() => { store.setStartDate(newDate) }, 1)} disableNewStyle />}

                        {mode == 'fortnight' &&
                            <div className={classes.fortnightPicker}>
                                <Switch
                                    form
                                    changeCallback={() => store.toggleSecondFortnight()}
                                    isChecked={store.isSecondFortnight}
                                    offText={t('mktSales.forecast.firstFortnight')} onText={t('mktSales.forecast.secondFortnight')} />
                            </div>
                        }
                    </div>
                    {store.isCompanyMonthFilled() && store.documentKeyDate && isManager &&
                        <div className={classes.buttonsContainer}>
                            <AttachFileButton
                                id='upload-provisonal-doc-button'
                                title={t('mktSales.label.documentDialogTitle')}
                                disableDelOrAdd={!isManager}
                                context={store.mktSaleForecast.company}
                                keyTemplate='mktsale-{company}-{documentDate}'
                                keyParameters={{ company: store.mktSaleForecast.company ?? '', documentDate: moment(store.documentKeyDate ?? '').format('YYYY-MM-DD') }} />
                        </div>
                    }
                    {snapshotTypes.map(type => (
                        <React.Fragment key={type}>
                            {store.hasSavedSnapshot(type)
                                && <Typography variant='h6' className={classes.snapshotText}>{t('mktSales.forecast.existingSnapshot', { type: getSnapshotLabel(type) }) +
                                    moment(store.getSnapshot(type)!.createdAt).format('MM/DD/YYYY')}</Typography>
                            }
                            {!store.hasSavedSnapshot(type) && isManager
                                && <Button onClick={() => store.showCreationDialog(type)}
                                    label={t('mktSales.forecast.saveForecast', { type: getSnapshotLabel(type) })}
                                    className={classes.secondaryButton}
                                    disabled={!store.isCompanyMonthFilled()} />
                            }
                            {store.hasSavedSnapshot(type) && isManager
                                && <div className={classes.buttonsContainer}>
                                    <Button onClick={() => store.showCancellationDialog(type)}
                                        label={t('mktSales.forecast.cancelForecast', { type: getSnapshotLabel(type) })}
                                        className={classes.secondaryButton}
                                        disabled={!store.isCompanyMonthFilled()} />
                                </div>
                            }
                        </React.Fragment>
                    ))}
                </Paper>
                {store.isCompanyMonthFilled() && store.isProductsByCustomerSegmentSet() && store.productsByCustomerSegment &&
                    <Paper>
                        <div>
                            <Tabs classes={{ indicator: classes.TabsIndicatorColor }} value={tabIndex} onChange={(_, i) => setTabIndex(i)}>
                                {Object.keys(store.customerSegmentGroupedByChannel).map((x, i) => <Tab key={i} label={x} classes={{ textColorInherit: classes.TabTextColor }} />)}
                            </Tabs>
                            {Object.keys(store.customerSegmentGroupedByChannel).map((x, i) =>
                                <Typography
                                    key={i}
                                    component='div'
                                    role='tabpanel'
                                    hidden={tabIndex !== i}
                                    aria-labelledby={`wrapped-tab-${i}`}>
                                    {store.customerSegmentGroupedByChannel[x].filter(x => store.productsByCustomerSegment[x.customerSegment]).map((y, i) =>
                                        <CustomerSegmentTable key={i}
                                            channelCustomerSegment={y}
                                            mktSaleForecast={store.mktSaleForecast}
                                            products={store.productsByCustomerSegment[y.customerSegment]}
                                            setMarketSalesForecastValue={setMarketSalesForecastValue}
                                            sites={store.sites} setMktSaleForecast={store.setMktSaleForecast} />
                                    )}
                                </Typography>)}

                        </div>
                    </Paper>
                }

                <SnapshotDialogs snapshotType={store.snapshotType}
                    isSnapshotCreationDialogOpen={store.isSnapshotCreationDialogOpen}
                    hideCreationDialog={store.hideCreationDialog}
                    createSnapshot={store.createSnapshot}
                    isSnapshotCancellationDialogOpen={store.isSnapshotCancellationDialogOpen}
                    hideCancellationDialog={store.hideCancellationDialog}
                    cancelSnapshot={store.cancelSnapshot} />
            </div>
        </Popup>
    )
}

let styles = theme =>
    createStyles({
        paperFilter: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            height: 'auto',
            padding: '0.5em',
            margin: '0.5em 0em 0.5em 0em'
        },
        fortnightPicker: {
            display: 'inline-flex',
            paddingTop: '1em'
        },
        snapshotText: { color: defaultColors.darkBlue.main.color },
        buttonsContainer: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
        },
        secondaryButton: { ...defaultStyles.secondaryButton },
        TabTextColor: { color: defaultColors.red.main.color },
        TabsIndicatorColor: { backgroundColor: defaultColors.red.main.color }
    })

export default withStyles(styles, muiOptions)(ForecastEdit)