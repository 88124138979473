import React from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import { muiOptions } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import { MultipleSelect, GroupedMultiSelect } from '../../common/customComponents'
import { StockFiltersContainer } from '../../../app/stock/filters/filtersStore'

function _SupplyBalanceFilters({ classes }) {
    let stockFiltersStore = StockFiltersContainer.useContainer()

    return (
        <div className="supplyBalanceFilters">
            <GroupedMultiSelect label={t('supplyBalance.filters.sites')} classesOverride={{ form: classes.filterField }}
                filter={stockFiltersStore.filters.sitesFilter} disableNewStyle />
            {stockFiltersStore.filters.productIdsFilter.items.every(x => !x.productGroup)
                ? <MultipleSelect label={t('supplyBalance.filters.products')} classesOverride={{ form: classes.filterField }}
                    filter={stockFiltersStore.filters.productIdsFilter} disableNewStyle hideOnSingleChoice />
                : <GroupedMultiSelect label={t('supplyBalance.filters.products')} classesOverride={{ form: classes.filterField }}
                    filter={stockFiltersStore.filters.productIdsFilter} hideOnSingleChoice disableNewStyle />}
            <MultipleSelect label={t('supplyBalance.filters.dutyStatus')} classesOverride={{ form: classes.filterField }}
                filter={stockFiltersStore.filters.dutyStatusesFilter} disableNewStyle />
            <MultipleSelect label={t('supplyBalance.filters.companies')} classesOverride={{ form: classes.filterField }}
                filter={stockFiltersStore.filters.companiesFilter} disableNewStyle />
        </div>
    )
}

let styles = _ =>
    createStyles({
        filterField: {
            width: '8.3em',
            margin: '0em 0.5em'
        }
    })

export let SupplyBalanceFilters = withStyles(styles, muiOptions)(_SupplyBalanceFilters)