import { Dialog, DialogContent, DialogActions, createStyles, withStyles, Typography, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core"
import * as Icons from '@material-ui/icons'
import React, { RefObject, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { MuiProps, defaultStyles, muiOptions } from "../../infrastructure/materialUiThemeProvider"
import { Button, CustomDialog, NumberField, Select, Switch } from "../common/customComponents"
import { t } from '../../infrastructure/i18nextHelper'
import { MktSalesContainer } from "./mktSalesStore"
import { ChannelCustomerSegment, Company, CustomerSegment, DutyStatus, MktSalesDemandPlanChange, defaultDemandPlanChange } from "./models"
import moment from 'moment'
import { api } from "../../infrastructure/api"
import { snackbars } from "../../infrastructure/snackbars"
import guid from '../../infrastructure/guid'
import { DatePicker } from "../common/components/datePicker/datePicker"
import { CommentButton } from "../common/comment/commentButton"
import { CommentContainer } from "../common/comment/commentStore"

let dialogRef: RefObject<{ open: () => void }> | null = null
type MktSalesDemandPlanChangeKeys = keyof MktSalesDemandPlanChange

function _MktSaleDemandPlan({ classes }: MuiProps) {
    let [demandPlanCount, setDemandPlanCount] = useState<number>(0)
    let store = MktSalesContainer.useContainer()

    useEffect(() => {
        let count = store.mktSaleDates.flatMap(x => x.movements).filter(x => x.hasDemandPlanChange).length
        setDemandPlanCount(count)
    }, [store.mktSaleDates])

    return (
        <>
            <div className={classes.container} onClick={() => dialogRef?.current?.open()}>
                <div className={classes.title}>{t('mktSales.demandPlan.title')}</div>
                <Button className={classes.confirmButton + ' ' + classes.restrictionButton}
                    label={<span className={classes.restrictionButtonText}>
                        <Typography variant='caption' className={classes.secondaryText}>
                            {demandPlanCount > 0
                                ? t('mktSales.demandPlan.existingChanges')
                                : t('mktSales.demandPlan.noChange')
                            }
                        </Typography>
                    </span>}
                    color='primary' />
            </div>
            <MktSaleDemandPlanDialog classes={classes}></MktSaleDemandPlanDialog>
        </>
    )
}

function _MktSaleDemandPlanDialog({ classes }: MuiProps) {
    let [isOpen, setIsOpen] = useState<boolean>(false)
    let [demandPlanChanges, setDemandPlanChanges] = useState<MktSalesDemandPlanChange[]>([])
    let [companys, setCompanys] = useState<Company[]>([])
    let [showConfirmSave, setShowConfirmSave] = useState<boolean>(false)
    let [showConfirmUnsavedChanges, setShowConfirmUnsavedChanges] = useState<boolean>(false)
    let [showCompanyOrDutyStatus, setShowCompanyOrDutyStatus] = useState<boolean>(false)
    let store = MktSalesContainer.useContainer()
    let commentStore = CommentContainer.useContainer()

    dialogRef = useRef<{ open: () => void }>(null)

    useImperativeHandle(dialogRef, () => ({
        open: () => setIsOpen(true)
    }))

    useEffect(() => {
        setShowCompanyOrDutyStatus(store.companies.length > 1)
        setCompanyValues()
    }, [store.companies, store.channelCustomerSegments])

    let setCompanyValues = () => {
        let customerSegments = store.channelCustomerSegments
            .groupBy(x => x.company, x => x)
            .map(([key, items]) => ({
                company: key,
                customerSegments: items.flatMap(x => x.customerSegment).distinct()
            }))

        let companys = store.companies
            .map(x => ({
                ...x,
                customerSegments: customerSegments.find(y => y.company === x.code)?.customerSegments ?? []
            }))

        setCompanys(companys)
    }

    let getDefaultCompany = () => showCompanyOrDutyStatus ? null : store.companies[0].code

    let getDefaultDutyStatusByCompany = (company: string | null) => {
        if (!company)
            return null

        let dutyStatuss = companys.find(x => x.code === company)?.dutyStatuses ?? []
        if (dutyStatuss.length === 1)
            return dutyStatuss[0]

        return null
    }

    let addNewLine = () => {
        let newArray = [...demandPlanChanges]
        let tomorrow = moment().add(1, 'days').toISOString()
        let defaultCompany = getDefaultCompany()

        newArray.push({
            ...defaultDemandPlanChange(),
            id: guid.createNew(),
            company: defaultCompany,
            dutyStatus: getDefaultDutyStatusByCompany(defaultCompany),
            dateFrom: tomorrow,
            dateTo: tomorrow
        })

        setDemandPlanChanges(newArray)
    }

    let isValidPeriod = (start: string, end: string) => {
        let dateFrom = moment(start)
        let dateTo = moment(end)

        return dateFrom.isValid() && dateTo.isValid() && dateFrom.isSameOrBefore(dateTo)
    }

    let isValidPerimeter = (demand: MktSalesDemandPlanChange) => !!demand.productId && !!demand.site

    let getForecastPeriod = (demand: MktSalesDemandPlanChange): { start: string, end: string } => {
        if (demand.takeForecastValue)
            return {
                start: moment(demand.dateFrom).format('MM/DD/YYYY'),
                end: moment(demand.dateTo).format('MM/DD/YYYY')
            }

        return {
            start: moment(demand.dateFrom).subtract(7, 'days').format('MM/DD/YYYY'),
            end: moment(demand.dateFrom).subtract(1, 'days').format('MM/DD/YYYY')
        };
    }

    let computeDays = (demand: MktSalesDemandPlanChange) => {
        let days = moment(demand.dateTo).diff(moment(demand.dateFrom), 'days') + 1
        setValue(demand, 'days', days)
    }

    let computeWeightedDays = async (demand: MktSalesDemandPlanChange) => {
        let customerSegmentProducts = await api.get<CustomerSegment[]>(
            `stock/movement/mktsale/customerSegmentProduct/${demand.company}/${demand.dateFrom}/${demand.dateTo}`)

        let segment = customerSegmentProducts.find(x => (!demand.company || demand.company === x.company)
            && (!demand.customerSegment || demand.customerSegment === x.code)
            && (!demand.dutyStatus || demand.dutyStatus === x.dutyStatus)
            && (!demand.site || demand.site === x.site))

        let totalWeight = segment?.products
            .find(x => (!demand.productId || demand.productId === x.id))?.periodCoeff ?? 0

        setValue(demand, 'weightedDays', totalWeight)
    }

    let changeOriginOfValue = (demand: MktSalesDemandPlanChange) => {
        setValue(demand, 'takeForecastValue', !demand.takeForecastValue)
        computeVolumes(demand)
    }

    let computeVolumes = async (demand: MktSalesDemandPlanChange) => {
        let period = getForecastPeriod(demand)

        let totalOfQuantity = await api.get<number>(`stock/movement/mktsale/totalQuantity`, {
            dateFrom: period.start,
            dateTo: period.end,
            productId: demand.productId,
            site: demand.site,
            company: demand.company,
            customerSegment: demand.customerSegment,
            takeForecast: demand.takeForecastValue
        })

        let divisor = demand.takeForecastValue ? demand.weightedDays : 7
        if (divisor)
            setVolumePerDay(demand, Math.round(totalOfQuantity / divisor), true)
    }

    let setTotalVolume = (demand: MktSalesDemandPlanChange, value: number | null) => {
        let volume = value === null || isNaN(value) ? 0 : +value;
        setValue(demand, 'newTotalPlannedVolume', volume)

        if (!!demand.weightedDays) {
            let volumePerDay = Math.round(volume / demand.weightedDays)
            setValue(demand, 'newPlannedVolumePerDay', volumePerDay)
        }
    }

    let setVolumePerDay = (demand: MktSalesDemandPlanChange, value: number | null, setOldTotalVolume: boolean = false) => {
        let volume = value === null || isNaN(value) ? 0 : +value;
        setValue(demand, 'newPlannedVolumePerDay', volume)

        let totalVolume = Math.round(volume * demand.weightedDays)
        setValue(demand, 'newTotalPlannedVolume', totalVolume)

        if (setOldTotalVolume)
            setValue(demand, 'oldTotalPlannedVolume', totalVolume)
    }

    let isValidRow = (demand: MktSalesDemandPlanChange, index: number) => {
        let isValid = true
        let indexText = { index: `#${++index}` }

        if (!isValidPeriod(demand.dateFrom, demand.dateTo)) {
            snackbars.warning(t('mktSales.demandPlan.dialog.messages.invalidDates', indexText))
            isValid = false
        }

        if (!demand.productId || demand.productId === guid.empty) {
            snackbars.warning(t('mktSales.demandPlan.dialog.messages.invalidProduct', indexText))
            isValid = false
        }

        if (!demand.site) {
            snackbars.warning(t('mktSales.demandPlan.dialog.messages.invalidSite', indexText))
            isValid = false
        }

        if (demandPlanChanges.some(x => !commentStore.existingKeys.has(getAssociationKey(x)))) {
            snackbars.warning(t('mktSales.demandPlan.dialog.messages.emptyComment', indexText))
            isValid = false
        }

        return isValid
    }

    let setValue = <K extends MktSalesDemandPlanChangeKeys>(demand: MktSalesDemandPlanChange, prop: K, value) => {
        demand[prop] = value
        let newArray = [...demandPlanChanges].map(x => x.id === demand.id ? demand : x)
        setDemandPlanChanges(newArray)
    }

    let setAndComputeValues = async <K extends MktSalesDemandPlanChangeKeys>(demand: MktSalesDemandPlanChange, prop: K, value: string | null) => {
        setValue(demand, prop, value)

        if (isValidPeriod(demand.dateFrom, demand.dateTo)) {
            computeDays(demand)
            await computeWeightedDays(demand)

            if (isValidPerimeter(demand))
                computeVolumes(demand)
        }
    }

    let setDemandDateFrom = (demand: MktSalesDemandPlanChange, newDate: string | null) => {
        setAndComputeValues(demand, 'dateFrom', newDate)

        if (!demand.dateTo || moment(newDate).isAfter(demand.dateTo))
            setAndComputeValues(demand, 'dateTo', newDate)
    }

    let setDemandDateTo = (demand: MktSalesDemandPlanChange, newDate: string | null) => {
        setAndComputeValues(demand, 'dateTo', newDate)

        if (!demand.dateFrom || (moment(newDate).isBefore(demand.dateFrom)))
            setAndComputeValues(demand, 'dateFrom', newDate)
    }

    let setCompany = (demand: MktSalesDemandPlanChange, company: string | null) => {
        setAndComputeValues(demand, 'company', company)
        setAndComputeValues(demand, 'dutyStatus', null)
        setAndComputeValues(demand, 'customerSegment', null)
    }

    let getDutyStatussChoices = (demand: MktSalesDemandPlanChange) => {
        let choices = companys.find(x => x.code === demand.company)?.dutyStatuses ?? []
        return choices.map(x => ({ value: x, text: x }))
    }

    let getCustomerSegmentsChoices = (demand: MktSalesDemandPlanChange) => {
        let choices = companys.find(x => x.code === demand.company)?.customerSegments ?? []
        return choices.map(x => ({ value: x, text: x }))
    }

    let closeMktSaleDemandPlanDialog = () => {
        setDemandPlanChanges([])
        setIsOpen(false)
    }

    let closeConfirmUnsavedChangesDialog = () => {
        setShowConfirmUnsavedChanges(false)
        closeMktSaleDemandPlanDialog()
    }

    let checkUnsavedChanges = () => {
        let hasUnsavedChanges = demandPlanChanges.length > 0
        if (hasUnsavedChanges) {
            setShowConfirmUnsavedChanges(true)
            return
        }
        closeMktSaleDemandPlanDialog()
    }

    let validateChanges = () => {
        if (demandPlanChanges.some((x, index) => !isValidRow(x, index)))
            return

        setShowConfirmSave(true)
    }

    let saveChanges = async () => {
        try {
            await api.post(`stock/movement/mktsale/demandPlanChange`, { demandPlanChanges })
            setShowConfirmSave(false)
            closeMktSaleDemandPlanDialog()
            snackbars.success(t('mktSales.demandPlan.dialog.messages.savedSuccess'))
            await store.loadMktSaleMovements()
        }
        catch (err) {
            setShowConfirmSave(false)
        }
    }

    let getAssociationKey = (demand: MktSalesDemandPlanChange) => `demand-plan-change-${demand.id}`

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={() => { }}
                scroll={'paper'}
                aria-labelledby='scroll-dialog-title'
                aria-describedby='scroll-dialog-description'
                fullWidth={true}
                maxWidth={'xl'}
                classes={{ paper: classes.dialog }}>
                <DialogContent>
                    <div className={classes.dialogTitle}>
                        <div>
                            <Typography variant='h5'>{t('mktSales.demandPlan.dialog.demandManagementRules')}</Typography>
                            <Typography className={classes.titleExplanation} variant='subtitle1'>{t('mktSales.demandPlan.dialog.titleExplanation')}</Typography>
                        </div>
                        <Button
                            onClick={addNewLine}
                            label={t('mktSales.demandPlan.dialog.addNewLine')}
                            img={<Icons.Add />}
                            className={classes.addNewLineButton} />
                    </div>
                    <div className={showCompanyOrDutyStatus ? classes.fullTableContainer : ''}>
                        <Table className={classes.table} aria-label='table'>
                            <TableHead className={classes.thead}>
                                <TableRow className={classes.row} key={"header"}>
                                    <TableCell>{t('mktSales.demandPlan.dialog.dateFrom')}</TableCell>
                                    <TableCell>{t('mktSales.demandPlan.dialog.dateTo')}</TableCell>
                                    <TableCell align={'center'}>{t('mktSales.demandPlan.dialog.days')}</TableCell>
                                    <TableCell align={'center'}>{t('mktSales.demandPlan.dialog.weightDays')}</TableCell>
                                    {showCompanyOrDutyStatus &&
                                        <TableCell>{t('mktSales.demandPlan.dialog.company')}</TableCell>}
                                    <TableCell>{t('mktSales.demandPlan.dialog.product')}</TableCell>
                                    <TableCell>{t('mktSales.demandPlan.dialog.site')}</TableCell>
                                    {showCompanyOrDutyStatus &&
                                        <TableCell>{t('mktSales.demandPlan.dialog.dutyStatus')}</TableCell>}
                                    <TableCell>{t('mktSales.demandPlan.dialog.customerSegment')}</TableCell>
                                    <TableCell align="center">{t('mktSales.demandPlan.dialog.originOfValue')}</TableCell>
                                    <TableCell align={'right'} className={classes.cellPaddingRight}>{t('mktSales.demandPlan.dialog.plannedVolPerDay')}</TableCell>
                                    <TableCell align={'right'} className={classes.cellPaddingRight}>{t('mktSales.demandPlan.dialog.plannedTotalVol')}</TableCell>
                                    <TableCell align="center">{t('mktSales.demandPlan.dialog.comment')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {demandPlanChanges.map(demand =>
                                    <TableRow className={classes.row} key={demand.id}>
                                        <TableCell className={classes.mediumCell}>
                                            <DatePicker
                                                disableNewStyle
                                                date={demand.dateFrom}
                                                setDate={newDate => setDemandDateFrom(demand, newDate)}
                                                label={''}
                                                smallIcon={true}
                                                classesOverride={{ datepicker: classes.smallSize }} />
                                        </TableCell>
                                        <TableCell className={classes.mediumCell}>
                                            <DatePicker
                                                disableNewStyle
                                                date={demand.dateTo}
                                                setDate={newDate => setDemandDateTo(demand, newDate)}
                                                label={''}
                                                smallIcon={true}
                                                classesOverride={{ datepicker: classes.smallSize }} />
                                        </TableCell>
                                        <TableCell className={classes.smallCell} align={'center'}>{demand.days}</TableCell>
                                        <TableCell className={classes.smallCell} align={'center'}>{demand.weightedDays}</TableCell>
                                        {showCompanyOrDutyStatus &&
                                            <TableCell className={classes.mediumCell}>
                                                <Select disableNewStyle isClearable
                                                    label={''}
                                                    value={demand.company}
                                                    choices={companys.map(x => ({ value: x.code, text: x.name }))}
                                                    onChange={x => setCompany(demand, x)}
                                                    classesOverride={{ form: classes.smallCell }} />
                                            </TableCell>}
                                        <TableCell className={classes.mediumCell}>
                                            <Select disableNewStyle
                                                label={''}
                                                value={demand.productId}
                                                choices={store.products.map(x => ({ value: x.id, text: x.code }))}
                                                onChange={x => setAndComputeValues(demand, 'productId', x)}
                                                classesOverride={{ form: classes.mediumCell }} />
                                        </TableCell>
                                        <TableCell className={classes.mediumCell}>
                                            <Select disableNewStyle
                                                label={''}
                                                value={demand.site}
                                                choices={store.sites.map(x => ({ value: x.code, text: x.name }))}
                                                onChange={x => setAndComputeValues(demand, 'site', x)}
                                                classesOverride={{ form: classes.mediumCell }} />
                                        </TableCell>
                                        {showCompanyOrDutyStatus &&
                                            <TableCell className={classes.mediumCell}>
                                                <Select disableNewStyle isClearable
                                                    label={''}
                                                    value={demand.dutyStatus}
                                                    choices={getDutyStatussChoices(demand)}
                                                    onChange={x => setAndComputeValues(demand, 'dutyStatus', x)}
                                                    classesOverride={{ form: classes.mediumCell }} />
                                            </TableCell>}
                                        <TableCell className={classes.mediumCell}>
                                            <Select disableNewStyle isClearable
                                                label={''}
                                                value={demand.customerSegment}
                                                choices={getCustomerSegmentsChoices(demand)}
                                                onChange={x => setAndComputeValues(demand, 'customerSegment', x)}
                                                classesOverride={{ form: classes.mediumCell }} />
                                        </TableCell>
                                        <TableCell className={classes.largeCell}>
                                            <Switch form
                                                isChecked={demand.takeForecastValue}
                                                changeCallback={() => changeOriginOfValue(demand)}
                                                className={classes.switch}
                                                offText={t('mktSales.demandPlan.dialog.lastSevenDays')}
                                                onText={t('mktSales.demandPlan.dialog.forecast')} />
                                        </TableCell>
                                        <TableCell className={classes.smallCell} align={'right'}>
                                            <NumberField disableNewStyle
                                                onChange={x => setVolumePerDay(demand, x)}
                                                size={'small'}
                                                text={demand.newPlannedVolumePerDay}
                                                align={'right'} />
                                        </TableCell>
                                        <TableCell className={classes.smallCell} align={'right'}>
                                            <NumberField disableNewStyle
                                                onChange={x => setTotalVolume(demand, x)}
                                                size={'small'}
                                                text={demand.newTotalPlannedVolume}
                                                align={'right'} />
                                        </TableCell>
                                        <TableCell className={classes.smallCell} align="center">
                                            <CommentButton associationKey={getAssociationKey(demand)} />
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.closeButton}
                        label={t('comment.closeButton')}
                        onClick={checkUnsavedChanges}
                        color='primary' />
                    <Button className={classes.saveButton}
                        disabled={!demandPlanChanges.length}
                        label={t('comment.saveButton')}
                        onClick={validateChanges}
                        color='primary' />
                </DialogActions>
            </Dialog >

            <CustomDialog isOpen={showConfirmSave}
                title={t('mktSales.demandPlan.dialog.confirmation')}
                contentText={t('mktSales.demandPlan.dialog.confirmSave')}
                confirmButtonText={t('components.dialogYes')}
                onConfirm={saveChanges}
                onClose={() => { }}
                onCancel={() => setShowConfirmSave(false)} />

            <CustomDialog isOpen={showConfirmUnsavedChanges}
                title={t('mktSales.demandPlan.dialog.confirmation')}
                contentText={t('mktSales.demandPlan.dialog.confirmUnsavedChanges')}
                confirmButtonText={t('components.dialogYes')}
                onConfirm={closeConfirmUnsavedChangesDialog}
                onClose={() => { }}
                onCancel={() => setShowConfirmUnsavedChanges(false)} />
        </>)
}
let styles = () =>
    createStyles({
        container: {
            padding: '0 1em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            cursor: 'pointer'
        },
        title: {
            color: 'rgba(0, 0, 0, 0.54)',
            fontSize: '0.8em',
            fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
            marginTop: '0.5em'
        },
        titleExplanation: {
            padding: 0,
            margin: 0,
            fontStyle: 'italic',
            fontWeight: 'normal'
        },
        table: {
            border: '1px solid #ddd',
            marginTop: '1em'
        },
        fullTableContainer: { minWidth: '104em' },
        smallSize: { width: '9em' },
        noMarginTop: { marginTop: '0 !important' },
        row: {
            "& td": { padding: '0.5em' },
            "& td:first-child, th:first-child": { paddingLeft: '0.9em' },
            "& th": { padding: '0.8em 0.3em 0.8em 0.3em' },
            "&:hover": { backgroundColor: '#fafafa' },
        },
        thead: { backgroundColor: '#fafafa' },
        switch: {
            ...defaultStyles.flexRow,
            justifyContent: 'start',
            minWidth: '0'
        },
        largeCell: { width: '15em' },
        mediumCell: { width: '10em' },
        smallCell: { width: '4em' },
        cellPaddingRight: { paddingRight: '1em !important' },
        commentIcon: {
            height: '1em',
            width: '1em',
        },
        addNewLineButton: {
            ...defaultStyles.primaryButton,
            marginRight: '0.5em'
        },
        dialogTitle: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            "& h2": { margin: '0 0 0.1em' },
            "& h6": { fontSize: 'medium' },
            position: 'sticky',
            left: '0'
        },
        dialog: {
            position: 'absolute',
            top: 0,
            left: 0
        }
    })

export let MktSaleDemandPlan = withStyles(styles, muiOptions)(_MktSaleDemandPlan)
export let MktSaleDemandPlanDialog = withStyles(styles, muiOptions)(_MktSaleDemandPlanDialog)