import { DealMovementItem } from "../deals/dealModels";
import { MovementListItem, SapFlowStepStatus } from "../stock/stockModels";

export type SapFlowStepName = 'STO' | 'PO' | 'GR' | 'SO' | 'DN' | 'GI' | 'GT' | 'MD' | 'RE'

export type SapTypes = 'sale' | 'sto' | 'stoDn' | 'stoGoodsIssue' | 'migo' | 'directTransfer' | 'rebranding309' |
    'purchase501' | 'gains' | 'losses' | 'poMigo' | 'poRelease' | 'soDn' | 'soGoodsIssue'

export type TransferFlowType = 'sto' | 'direct301'

export type SapFlowStep = {
    shortName: SapFlowStepName | null
    sapType: SapTypes
    sapFlowType: TransferFlowType | null
}

export let PurchaseOrder: SapFlowStep = { shortName: 'PO', sapType: 'purchase501', sapFlowType: null }
export let GoodReceipt: SapFlowStep = { shortName: 'GR', sapType: 'poMigo', sapFlowType: null }
export let PurchaseRelease: SapFlowStep = { shortName: 'RE', sapType: 'poRelease', sapFlowType: null }
export let SalesOrder: SapFlowStep = { shortName: 'SO', sapType: 'sale', sapFlowType: null }
export let SaleDeliveryNote: SapFlowStep = { shortName: 'DN', sapType: 'soDn', sapFlowType: null }
export let SaleGoodsIssue: SapFlowStep = { shortName: 'GI', sapType: 'soGoodsIssue', sapFlowType: null }
export let StockTransferOrder: SapFlowStep = { shortName: 'STO', sapType: 'sto', sapFlowType: 'sto' }
export let TransferDeliveryNote: SapFlowStep = { shortName: 'DN', sapType: 'stoDn', sapFlowType: 'sto' }
export let TransferGoodsIssue: SapFlowStep = { shortName: 'GI', sapType: 'stoGoodsIssue', sapFlowType: 'sto' }
export let GoodsTransfer: SapFlowStep = { shortName: 'GT', sapType: 'migo', sapFlowType: 'sto' }
export let DirectTransfer: SapFlowStep = { shortName: null, sapType: 'directTransfer', sapFlowType: 'direct301' }
export let Rebranding: SapFlowStep = { shortName: null, sapType: 'rebranding309', sapFlowType: null }
export let Gains: SapFlowStep = { shortName: null, sapType: 'gains', sapFlowType: null }
export let Losses: SapFlowStep = { shortName: null, sapType: 'losses', sapFlowType: null }

export const goodsMovementAction: SapTypes[] = [
    GoodsTransfer.sapType, DirectTransfer.sapType, Rebranding.sapType, Gains.sapType, Losses.sapType, GoodReceipt.sapType
]
export const poCreateAction: SapTypes[] = [StockTransferOrder.sapType, PurchaseOrder.sapType]
export const soCreateAction: SapTypes = SalesOrder.sapType
export const poReleaseAction: SapTypes = PurchaseRelease.sapType
export const deliveryAction: SapTypes[] = [TransferDeliveryNote.sapType, SaleDeliveryNote.sapType]
export const goodsIssueAction: SapTypes[] = [TransferGoodsIssue.sapType, SaleGoodsIssue.sapType]

export let isMultiSapFlow = (x: DealMovementItem | MovementListItem) =>
    x.secondSapFlowListItem != null && x.secondSapFlowListItem.steps.length > 0

export let steps = (x: MovementListItem | DealMovementItem, showAll: boolean)
    : { mainSapFlow: SapFlowStepStatus[], secondSapFlow?: SapFlowStepStatus[] } => {
    if (showAll)
        return {
            mainSapFlow: x.mainSapFlowListItem.steps,
            secondSapFlow: x.secondSapFlowListItem?.steps
        }
    else if (isMultiSapFlow(x))
        return {
            mainSapFlow: [x.mainSapFlowListItem.steps[0], x.secondSapFlowListItem!.steps[0]]
        }
    else
        return {
            mainSapFlow: x.mainSapFlowListItem.steps
        }
}

export let isTransferSapType = (sapType: SapTypes) =>
    sapType == StockTransferOrder.sapType || sapType == TransferDeliveryNote.sapType
    || sapType == TransferGoodsIssue.sapType || sapType == DirectTransfer.sapType
    || sapType == GoodsTransfer.sapType