import { createStyles, Drawer, Input, Paper, Tooltip, Typography, IconButton, withStyles } from '@material-ui/core'
import { defaultColors, defaultStyles, muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import React, { useState, useRef, useEffect } from 'react'
import { Button, DatePicker, LabelButton, ColumnDescriptor, CustomDialog, DataTable, TableItem } from '../../common/customComponents'
import { t } from '../../../infrastructure/i18nextHelper'
import { snackbars } from '../../../infrastructure/snackbars'
import guid, { Guid } from '../../../infrastructure/guid'
import { ExcelCell, ExcelLine } from '../../../infrastructure/excelExport'
import { ActionDescriptor } from '../../common/components/table/table'
import * as Icons from '@material-ui/icons'
import clsx from 'clsx'
import AddOutlined from '@material-ui/icons/AddOutlined'
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined'
import { allowedExtensions } from '../../common/fieldProps'

type ItemId = { id: Guid }

export type MasterDataItem<T> = T & ItemId

export type DrawerAction<T> = {
    name: string,
    onClick: (item: T) => Promise<boolean>
}

type MasterDataShellProps<T> = {
    items: TableItem<MasterDataItem<T>>[]
    headerLabel: string
    itemLabel?: string
    isManager?: boolean
    tableId?: string
    largeDrawer?: boolean
    dateFilter?: string | null
    drawerActions?: DrawerAction<T>[]
    onExportExcel?: () => void
    onDateFilter?: (date: string | null) => void
    onImportExcel?: (file: Blob) => void
    onNew?: () => T
    onDelete?: (ids: string[] | null) => Promise<boolean>
    onSave?: (item: T) => Promise<boolean>
    onCopy?: (item: TableItem<MasterDataItem<T>>) => TableItem<MasterDataItem<T>>
    onSelectedItem?: (item: T | null) => void,
    columns: ColumnDescriptor<TableItem<MasterDataItem<T>>>[],
    children?: any
    className?: string
} & MuiProps

function _MasterDataShell<T>({
    classes,
    className,
    headerLabel,
    itemLabel,
    isManager,
    tableId,
    largeDrawer,
    dateFilter,
    drawerActions,
    onExportExcel,
    onDateFilter,
    onImportExcel,
    onNew,
    onDelete,
    onSave,
    onCopy,
    onSelectedItem,
    items,
    columns,
    children
}: MasterDataShellProps<T>) {
    let [selectedItem, setSelectedItem] = useState<MasterDataItem<T> | null>(null)
    let [editorMode, setEditorMode] = useState<'edit' | 'create' | 'copy' | null>(null)
    let [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false)

    useEffect(() => {
        return onSelectedItem && onSelectedItem(selectedItem)
    }, [selectedItem])

    let drawerTop = useRef<null | HTMLDivElement>(null)

    let exportExcel = () => {
        if (onExportExcel)
            onExportExcel()
    }

    let importExcel = e => {
        if (!onImportExcel) return
        onImportExcel(e.target.files[0])
        e.target.value = ''
    }

    let newItem = () => {
        if (!onNew) return
        openEditor('create')
        setSelectedItem({ ...onNew(), id: guid.empty })
    }

    let editItem = (item: TableItem<MasterDataItem<T>>) => {
        if (!onSave || selectedItem && selectedItem.id === item.id) return
        openEditor('edit')
        setSelectedItem(item)
        setAllPristine()
        item.isModified = true
    }

    let copyItem = (item: TableItem<MasterDataItem<T>>) => {
        if (!onCopy) return
        item = onCopy(item)
        openEditor('copy')
        setSelectedItem({ ...item, id: guid.empty })
    }

    let closeEditor = () => {
        setSelectedItem(null)
        setEditorMode(null)
        setAllPristine()
    }

    let openEditor = (mode: 'edit' | 'create' | 'copy' | null) => {
        setEditorMode(mode)
        drawerTop?.current?.scrollIntoView({ behavior: 'smooth' })
    }

    let saveItem = async () => {
        if (!selectedItem || !onSave || !await onSave(selectedItem)) return
        if (editorMode == 'edit')
            snackbars.success(t('admin.masterdata.success.edit'))
        if (editorMode == 'create')
            snackbars.success(t('admin.masterdata.success.create'))

        closeEditor()
    }

    let onConfirmDeleteSelectedItem = async () => {
        if (!onDelete) return
        if (!selectedItem?.id || !await onDelete([selectedItem.id])) return
        snackbars.success(t('admin.masterdata.delete', { type: itemLabel }))
        setIsConfirmDialogOpen(false)

        closeEditor()
    }

    let deleteSelectedItems = async (toDelete: TableItem<MasterDataItem<T>>[]) => {
        if (!onDelete) return
        if (toDelete.length === 0) return
        if (!await onDelete(toDelete.map(x => x.id!))) return
        snackbars.success(t('admin.masterdata.delete', { type: itemLabel }))
    }

    let setAllPristine = () => items.filter(x => x.isModified).forEach(x => x.isModified = false)

    let actions: ActionDescriptor<TableItem<MasterDataItem<T>>>[] = isManager && onDelete ? [
        {
            name: 'Delete',
            action: (selected: TableItem<MasterDataItem<T>>[]) => deleteSelectedItems(selected),
            icon:
                <Tooltip title={<Typography variant='body1'>{t('admin.masterdata.delete', { type: itemLabel })}</Typography>} placement='top'>
                    <Icons.DeleteOutlined />
                </Tooltip>
        }
    ] : []

    onCopy && actions.push(
        {
            name: 'Duplicate',
            action: (x: TableItem<MasterDataItem<T>>) => { copyItem(x) },
            icon:
                <Tooltip title={<Typography variant='body1'>{t('admin.masterdata.copy', { type: itemLabel })}</Typography>} placement='top'>
                    <Icons.FileCopyOutlined />
                </Tooltip>,
            isBodyAction: true
        }
    )

    let getTitleText = (mode) => {
        switch (mode) {
            case 'create':
                return t('admin.masterdata.createTitle', { type: itemLabel })
            case 'edit':
                return t('admin.masterdata.editTitle', { type: itemLabel })
            case 'copy':
                return t('admin.masterdata.copyTitle', { type: itemLabel })
            default:
                return null
        }
    }

    return (
        <div className={className || ''}>
            <Paper className={
                clsx(classes.pageContent, { [classes.pageContentShift]: editorMode != null })}>
                <div className={classes.pageHead}>
                    <Typography className={classes.pageTitle} variant='overline' display='block' gutterBottom>
                        {headerLabel}
                    </Typography>
                    <div className={classes.flexRow}>
                        {onDateFilter &&
                            <DatePicker date={dateFilter || null}
                                label={t('admin.masterdata.dateFilterFrom')}
                                disableNewStyle
                                setDate={newDate => onDateFilter(newDate)}
                                classesOverride={{ datepicker: classes.filterFieldDate }} />}
                        {onExportExcel &&
                            <Button className={classes.headerButton}
                                img='/static/images/excel_red.svg'
                                label={t('admin.masterdata.export')}
                                onClick={exportExcel} />}
                        {isManager && onImportExcel && <>
                            <LabelButton className={classes.headerButton}
                                classes={{ label: classes.buttonFullHeightLabel }}
                                img='/static/images/excel_red.svg'
                                label={t('admin.masterdata.import')}>
                                <Input style={{ display: 'none' }} type='file'
                                    onChange={e => importExcel(e)}
                                    inputProps={allowedExtensions} />
                            </LabelButton>
                        </>}
                        {isManager && onNew &&
                            <Button className={classes.headerButton}
                                onClick={newItem}
                                img={<AddOutlined />}
                                label={t('admin.masterdata.createNew')} />}
                    </div>
                </div>
                <div className={onDateFilter ? classes.pageBodyWithDateFilter : classes.pageBodyWithoutDateFilter}>
                    <DataTable
                        tableId={tableId}
                        items={items}
                        idSelector={(x: TableItem<MasterDataItem<T>>) => x.id}
                        onClick={editItem}
                        columns={columns}
                        actions={actions} />
                </div >
            </Paper>
            <Drawer
                className={classes.drawer}
                variant='persistent'
                anchor='right'
                open={editorMode != null}
                classes={{ paper: largeDrawer ? classes.largeDrawerPaper : classes.drawerPaper }}>
                <div className={classes.drawerHeader} ref={drawerTop}>
                    <Tooltip className={classes.closeDrawerButton} title={<Typography variant='body1'>{t('admin.masterdata.closeDrawer')}</Typography>} placement='left'>
                        <span>
                            <IconButton onClick={() => closeEditor()}>
                                <ArrowForwardIosOutlinedIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Typography variant='caption' className={classes.drawerTitle}>
                        {getTitleText(editorMode)}
                    </Typography>
                </div>
                <div className={classes.drawerBody}>
                    {selectedItem && children(selectedItem, setSelectedItem)}
                </div>
                <div className={classes.drawerFooter}>
                    <Button className={classes.confirmButton}
                        img={<Icons.SaveOutlined />}
                        label={editorMode == 'create' || editorMode == 'copy'
                            ? t('admin.masterdata.create')
                            : t('admin.masterdata.edit')
                        }
                        onClick={saveItem} />
                    {(editorMode !== 'create' && editorMode !== 'copy') && onDelete
                        ? <Button className={classes.deleteButton}
                            img={<Icons.DeleteOutlined />}
                            label={t('admin.masterdata.deleteItem')}
                            onClick={() => setIsConfirmDialogOpen(true)} />
                        : null}
                    {drawerActions && drawerActions.map(x =>
                        <Button key={x.name}
                            className={classes.drawerActionButton}
                            disabled={!isManager}
                            label={x.name}
                            onClick={() => { x.onClick(selectedItem!) }} />)}
                </div>
                <CustomDialog isOpen={isConfirmDialogOpen} title={t('admin.masterdata.confirmDeletionTitle')}
                    contentText={t('admin.masterdata.confirmDeletionText')}
                    confirmButtonText={t('admin.masterdata.confirmDeletionDeleteButton')}
                    onConfirm={onConfirmDeleteSelectedItem} onClose={() => setIsConfirmDialogOpen(false)} onCancel={() => setIsConfirmDialogOpen(false)} />
            </Drawer>
        </div>
    )
}

let styles = (theme) =>
    createStyles({
        pageTitle: {
            color: defaultColors.red.main.color
        },
        flexRow: { ...defaultStyles.flexRow },
        pageHead: {
            ...defaultStyles.flexRow,
            padding: '0.5em 1em',
            justifyContent: 'space-between'
        },
        pageBodyWithDateFilter: {
            maxHeight: '85vh',
            overflow: 'auto'
        },
        pageBodyWithoutDateFilter: {
            maxHeight: '87vh',
            overflow: 'auto'
        },
        closeDrawerButton: {
            position: 'absolute',
            left: '0'
        },
        drawerPaper: {
            ...defaultStyles.flexColumn,
            justifyContent: 'space-between',
            alignItems: 'center',
            zIndex: 50,
            height: '100%',
            marginTop: '3em',
            backgroundColor: '#fafafa',
            width: '20em'
        },
        largeDrawerPaper: {
            ...defaultStyles.flexColumn,
            justifyContent: 'space-between',
            alignItems: 'center',
            zIndex: 50,
            height: '100%',
            marginTop: '3em',
            backgroundColor: '#fafafa',
            width: '50%'
        },
        drawerTitle: {
            fontSize: '1.2em'
        },
        drawerHeader: {
            ...defaultStyles.flexRow,
            justifyContent: 'center',
            padding: '1em',
            width: '85%',
            marginTop: '1.5em'
        },
        drawerBody: {
            ...defaultStyles.flexColumn,
            width: '100%;',
            padding: '1em',
            justifyContent: 'center',
            alignItems: 'normal',
            flexGrow: 1,
            '& > div': {
                marginTop: '1em',
                marginBottom: '0.5em'
            }
        },
        drawerFooter: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-end',
            width: '90%',
        },
        headerButton: {
            ...defaultStyles.secondaryButton,
            marginLeft: '2em'
        },
        confirmButton: {
            ...defaultStyles.primaryButton,
            marginBottom: '5em',
            marginRight: '0.5em',
            marginLeft: '0.5em',
            width: '100%'
        },
        deleteButton: {
            ...defaultStyles.secondaryButton,
            marginBottom: '5em',
            marginRight: '0.5em',
            marginLeft: '0.5em',
            width: '100%'
        },
        drawerActionButton: {
            ...defaultStyles.secondaryButton,
            marginBottom: '5em',
            marginRight: '0.5em',
            marginLeft: '0.5em',
            width: '100%'
        },
        pageContent: {
            flexGrow: 1,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            marginRight: '0em',
            overflowX: 'auto'
        },
        pageContentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
            }),
            marginRight: '19em'
        }
    })

export let MasterDataShell = withStyles(styles, muiOptions)(_MasterDataShell)

export function createExcelLines<T>(items: T[], columns: ColumnDescriptor<T>[]): ExcelLine[] {
    let headerLine: ExcelLine = {
        cells: columns.map(x => ({ type: 'string', value: x.name }))
    }
    let lines = items.map(x => {
        return {
            cells: columns.map<ExcelCell>(column => {
                if (column.type === 'stringDate') {
                    return ({
                        type: 'stringDate', value: ((column.valueForExport?.(x) ?? column.value?.(x) ?? '') + '')
                    })
                }
                else if (column.type === 'number') {
                    return ({
                        type: 'number', value: ((column.valueForExport?.(x) ?? column.value?.(x) ?? '') + '')
                    })
                }
                return ({
                    type: 'string', value: ((column.valueForExport?.(x) ?? column.value?.(x) ?? '') + '')
                })
            })
        }
    })
    return [headerLine].concat(lines)
}